import "../styles/home.scss";
import "../styles/single-customer.scss";
import "../styles/components.scss";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  getBatchShipmentsUrl,
  getBatchDetailsUrl,
  getBatchTimelineUrl,
  approveBatchUrl,
  acceptBatchUrl,
  getStationsURL,
  cancelBatchUrl,
  removeBatchShipmentUrl,
} from "../library/URLs";
import axios from "axios";
import { UserProfileData } from "../library/constants";
import {
  BsChevronDown,
  BsChevronLeft,
  BsChevronRight,
  BsChevronUp,
} from "react-icons/bs";
import styled from "styled-components";
import * as React from "react";
import moment from "moment";
import SubscriptionInactive from "../components/SubscriptionInactive";
import { toast } from "react-toastify";
import Tooltip from "@mui/material/Tooltip";
import AcceptBatchDialog from "../modals/AcceptBatchDialog";
import CancelBatchDialog from "../modals/CancelBatchDialog";
import RemoveShipmentDialog from "../modals/RemoveShipmentDialog";
import BatchApproveDialog from "../modals/BatchApproveDialog";
import { SearchBox } from "../components/InputFields";
import AddBatchDialog from "../modals/AddBatchDialog";
import { Menu, MenuItem } from "@mui/material";
import ExportIcon from "../assets/shipments/file_save.svg";
import * as ExcelJS from "exceljs";
import { FaBus } from "react-icons/fa";
import DispatchShipmentsDialog from "../modals/DispatchShipmentsDialog";
import DispatchIcon from "../assets/batches/dispatch.webp";

const LoadingContainer = styled.div`
  height: 350px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin: auto;
`;

const Box = styled.div`
  border: 1px solid #139c5f;
  background: #e8f7f0;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  margin-right: 16px;
  margin-top: 7px;
`;

const Timestamp = styled.span`
  font-size: 13px;
  margin-top: -25px;
  color: #98a2b3;
`;

const VerticalLine = styled.div`
  height: 65px;
  border-left: 2px solid #d6f9e4;
  margin-left: 5px;
`;

const ActivityDesc = styled.p`
  font-size: 13px;
  line-height: 17.4px;
  color: #1d2739;
  margin: 0;
`;

const SingleBatch = () => {
  const params = useParams();
  const navigate = useNavigate();
  const batchID = params["batchID"];

  const [selectedBatch, setSelectedBatch] = useState({});
  const [shipments, setShipments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [shipmentsLoading, setShipmentsLoading] = useState(false);
  const [timeline, setTimeline] = useState([]);
  const [stations, setStations] = useState([]);
  const [statusClass, setStatusClass] = useState("");
  const [selectedShipments, setSelectedShipments] = useState([]);
  const [cancelBatchModal, setCancelBatchModal] = useState(false);
  const [acceptBatchModal, setAcceptBatchModal] = useState(false);
  const [approveBatchModal, setApproveBatchModal] = useState(false);
  const [removeShipmentModal, setRemoveShipmentModal] = useState(null);
  const [acceptLoading, setAcceptLoading] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [removeShipmentLoading, setRemoveShipmentLoading] = useState(false);
  const [shipmentsQuery, setShipmentsQuery] = useState("");
  const [addBatch, setAddBatch] = useState(false);
  const [openExport, setOpenExport] = useState(null);
  const [dispatchShipments, setDispatchShipments] = useState(false);

  const open = Boolean(openExport);
  const handleClickExport = (event) => {
    setOpenExport(event.currentTarget);
  };
  const handleClose = () => {
    setOpenExport(null);
  };

  const data = {
    batch_number: batchID,
  };

  const newData = { ...data, ...UserProfileData() };

  const fetchBatch = () => {
    setIsLoading(true);
    axios
      .post(getBatchDetailsUrl, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setIsLoading(false);

        if (response["data"]["success"] === false) return;

        const batch = response["data"]["data"];
        fetchBatchShipments();
        let statusClass =
          batch?.status === "Accepted"
            ? "Delivered"
            : batch?.status.toString().replaceAll(" ", "");
        setStatusClass(statusClass);
        setSelectedBatch(batch);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const fetchStations = () => {
    axios
      .post(getStationsURL, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response["data"]["success"] === false) return;

        const newShipments = response["data"]["data"]["data"];
        setStations(newShipments);
      })
      .catch(() => {});
  };

  const approveBatch = (trackInfo) => {
    const { tracking_number, tracking_number_api } = trackInfo;

    let formValues = {
      ...newData,
      tracking_number,
      tracking_number_api,
    };
    setApproveLoading(true);
    axios
      .post(approveBatchUrl, formValues, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setApproveLoading(false);

        if (response["data"]["success"] === false) {
          toast.error("Error approving batch");
          return;
        }

        toast.success("Batch successfully Approved");
        fetchBatch();
        fetchTimeline();
        closeApproveBatch();
      })
      .catch(() => {
        setApproveLoading(false);
      });
  };

  const acceptBatch = () => {
    setAcceptLoading(true);
    axios
      .post(acceptBatchUrl, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setAcceptLoading(false);

        if (response["data"]["success"] === false) {
          toast.error(response["data"]["message"]);
          return;
        }

        toast.success("Batch successfully accepted");
        fetchBatch();
        fetchTimeline();
        closeAcceptBatch();
      })
      .catch(() => {
        setAcceptLoading(false);
      });
  };

  const cancelBatch = () => {
    setCancelLoading(true);
    axios
      .post(cancelBatchUrl, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setCancelLoading(false);

        if (response["data"]["success"] === false) {
          toast.error("Error cancelling batch");
          return;
        }

        toast.success("Batch successfully cancelled");
        fetchBatch();
        fetchTimeline();
        closeCancelBatch();
      })
      .catch(() => {
        setCancelLoading(false);
      });
  };

  const removeShipment = () => {
    setRemoveShipmentLoading(true);
    axios
      .post(
        removeBatchShipmentUrl,
        { ...newData, shipment_numbers: selectedShipments },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setRemoveShipmentLoading(false);

        if (response["data"]["success"] === false) {
          toast.error("Error removing shipment(s)");
          return;
        }

        toast.success("Shipment(s) successfully removed");
        fetchBatch();
        fetchTimeline();
        closeRemoveShipment();
        setSelectedShipments([]);
      })
      .catch(() => {
        setRemoveShipmentLoading(false);
      });
  };

  const fetchTimeline = () => {
    setIsLoading(true);
    axios
      .post(
        getBatchTimelineUrl,
        { ...newData, batch_number: batchID },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setIsLoading(false);

        if (response["data"]["success"] === false) return;

        setTimeline(response["data"]["data"]);

        // const batch = response["data"]["data"];
        // fetchBatchShipments();
        // setSelectedBatch(batch);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const fetchBatchShipments = () => {
    setShipmentsLoading(true);
    axios
      .post(
        getBatchShipmentsUrl,
        { ...newData, batch_number: batchID },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setShipmentsLoading(false);

        if (response["data"]["success"] === false) return;

        const newBatches = response["data"]["data"];

        setShipments(newBatches);
      })
      .catch(() => {
        setShipmentsLoading(false);
      });
  };

  useEffect(() => {
    fetchBatch();
    fetchTimeline();
    fetchStations();
    // eslint-disable-next-line
  }, []);

  const shortenTexts = (text) => {
    if (text) {
      if (text?.length > 25) {
        return `${text?.slice(0, 25)}...`;
      } else {
        return text;
      }
    }
  };

  const handleSelectAll = () => {
    let selectedShipmentCopy = [...selectedShipments];

    console.log("faf", shipments.length);
    console.log("gga", selectedShipments);
    if (selectedShipments.length === shipments.length) {
      selectedShipmentCopy = [];
      console.log("full");
    } else {
      let selection = shipments?.map((shipment) => shipment.shipment_no);
      selectedShipmentCopy = selection;
      console.log("empty");
    }
    setSelectedShipments(selectedShipmentCopy);
  };

  const handleShipmentToggle = (id) => {
    let selectedShipmentCopy = [...selectedShipments];

    if (selectedShipmentCopy.includes(id)) {
      selectedShipmentCopy = selectedShipmentCopy.filter(
        (shipmentId) => shipmentId !== id
      );
    } else {
      selectedShipmentCopy.push(id);
    }
    setSelectedShipments(selectedShipmentCopy);
  };

  const openApproveBatch = () => {
    setApproveBatchModal(true);
  };

  const closeApproveBatch = () => {
    setApproveBatchModal(false);
  };

  const openAcceptBatch = () => {
    setAcceptBatchModal(true);
  };

  const closeAcceptBatch = () => {
    setAcceptBatchModal(false);
  };

  const openCancelBatch = () => {
    setCancelBatchModal(true);
  };

  const closeCancelBatch = () => {
    setCancelBatchModal(false);
  };

  const openRemoveShipment = (shipments) => {
    setRemoveShipmentModal(shipments);
  };

  const closeRemoveShipment = () => {
    setRemoveShipmentModal(null);
  };

  const handleShipmentQuery = (e) => {
    const { value } = e.target;

    setShipmentsQuery(value);
  };

  const onSubmit = () => {
    setSelectedShipments([]);
  };

  const exportToCSV = () => {
    if (shipments.length === 0) return;

    let csvContent = "data:text/csv;charset=utf-8,";

    csvContent +=
      "Shipment ID,Shipment Type,Date,Sender Address,Reciver Address,Status,Date Created" +
      "\r\n";

    shipments.forEach(function (shipment) {
      let id = shipment.id;

      const row =
        id +
        "," +
        shipment.shipment_number +
        "," +
        shipment.shipment_type +
        "," +
        shipment.sender_address_1 +
        "," +
        shipment.receiver_address_1 +
        "," +
        shipment.status +
        "," +
        shipment.date_time;

      csvContent += row + "\r\n";
    });

    const encodedURI = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedURI);
    link.setAttribute("download", `${batchID} Shipments`);
    document.body.appendChild(link);

    link.click();
  };

  const exportToXlsx = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("AAJ Shipments");
    sheet.properties.defaultRowHeight = 40;

    sheet.getRow(1).border = {
      // top: { style: "thick", color: { argb: "074B8A" } },
      // left: { style: "thick", color: { argb: "074B8A" } },
      bottom: { style: "thick", color: { argb: "074B8A" } },
      right: { style: "thin", color: { argb: "074B8A" } },
    };

    sheet.getRow(1).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "008561" },
    };

    sheet.getRow(1).font = {
      // name: "Comic Sans MS",
      // family: 4,
      size: 16,
      bold: true,
      color: { argb: "000000" },
    };

    sheet.columns = [
      {
        header: "S/N",
        key: "sn",
        width: 5,
      },
      {
        header: "SHIPMENT ID",
        key: "id",
        width: 30,
      },
      {
        header: "SHIPMENT TYPE",
        key: "shipment_type",
        width: 40,
      },
      {
        header: "PICKUP ADDRESS",
        key: "sender_address_1",
        width: 60,
      },
      {
        header: "DROPOFF ADDRESS",
        key: "receiver_address_1",
        width: 60,
      },
      {
        header: "SHIPMENT STATUS",
        key: "status",
        width: 40,
      },
      {
        header: "DATE",
        key: "date_time",
        width: 40,
      },
    ];

    sheet.insertRow(1, [
      "",
      "",
      "",
      `
          ${batchID} SHIPMNETS
          `,
    ]);
    const first_row = sheet.getRow(1);
    first_row.height = 120;
    first_row.font = {
      size: 18,
      bold: true,
      color: { argb: "074B8A" },
    };
    first_row.border = {
      // top: { style: "thick", color: { argb: "074B8A" } },
      left: { style: "thick", color: { argb: "FFFFFF" } },
      bottom: { style: "thick", color: { argb: "074B8A" } },
      // right: { style: "thick", color: { argb: "074B8A" } },
    };
    first_row.alignment = { horizontal: "center" };

    // const result = toDataURL(User?.company_logo_url);
    // const result = User?.company_logo_url;

    // worksheet.addImage(imageId2, {
    //   tl: { col: 1.5, row: 1.5 },
    //   br: { col: 3.5, row: 5.5 }
    // });
    shipments?.forEach((shipment, id) => {
      sheet.addRow({
        sn: id + 1,
        id: `${shipment.shipment_number ?? "N/A"}`,
        shipment_type: `${shipment?.shipment_type ?? "N/A"}`,
        date_time: `${shipment?.date_time ?? "N/A"}`,
        sender_address_1: `${shipment?.sender_address_1 ?? "N/A"}`,
        receiver_address_1: `${shipment?.receiver_address_1 ?? "N/A"}`,
        status: `${shipment?.status ?? ""}`,
      });
    });
    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = `${batchID} Shipments`;
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  return (
    <div className="home">
      <BatchApproveDialog
        isModalVisible={approveBatchModal}
        handleCloseDialog={closeApproveBatch}
        approveBatch={approveBatch}
        isLoading={approveLoading}
      />

      <AcceptBatchDialog
        isModalVisible={acceptBatchModal}
        handleCloseDialog={closeAcceptBatch}
        acceptBatch={acceptBatch}
        isLoading={acceptLoading}
      />

      <CancelBatchDialog
        isModalVisible={cancelBatchModal}
        handleCloseDialog={closeCancelBatch}
        cancelBatch={cancelBatch}
        isLoading={cancelLoading}
      />

      <RemoveShipmentDialog
        isModalVisible={removeShipmentModal !== null}
        handleCloseDialog={closeRemoveShipment}
        removeShipments={removeShipment}
        isLoading={removeShipmentLoading}
        no_of_shipments={selectedShipments?.length}
      />

      <AddBatchDialog
        isModalVisible={addBatch}
        handleCloseDialog={() => setAddBatch(false)}
        shipments={selectedShipments}
        onSubmit={onSubmit}
        parent_batch_number={batchID}
      />

      <DispatchShipmentsDialog
        isModalVisible={dispatchShipments}
        handleCloseDialog={() => setDispatchShipments(false)}
        shipments={selectedShipments}
        state={selectedBatch?.destination_station?.state}
        batch_number={batchID}
      />

      <Sidebar />
      <div className="main">
        <Navbar />
        <div className="page pb-5">
          <SubscriptionInactive />

          <div className="container-fluid">
            <div className="navigation-contain">
              <button
                className="back-btn"
                onClick={() => navigate("/shipments/batches")}
              >
                <BsChevronLeft />
              </button>
              <ul className="nav-options">
                <li>Batches</li>
                <li className="nav-options-arrow">
                  <BsChevronRight />{" "}
                </li>
                <li className="nav-options-active">Batch Detail - {batchID}</li>
              </ul>
            </div>
            <div className="row mt-4">
              <div className="col-xl-12 col-md-12">
                <div
                  className="card border-0"
                  style={{ backgroundColor: "#FFF" }}
                >
                  <div className="card-body">
                    <div className="d-flex align-items-center justify-content-between gap-3 mb-3">
                      <div className={`status ${statusClass}`}>
                        {selectedBatch?.status}
                      </div>
                      <div className="d-flex gap-3 align-items-center">
                        {selectedBatch?.status === "Pending Approval" ? (
                          <button
                            className="btn-primary"
                            onClick={openApproveBatch}
                          >
                            Approve Batch
                          </button>
                        ) : selectedBatch?.status === "In Transit" ||
                          selectedBatch?.status === "Approved" ? (
                          <button
                            className="btn-primary"
                            onClick={openAcceptBatch}
                          >
                            Accept Batch
                          </button>
                        ) : null}
                        {(selectedBatch?.status === "In Transit" ||
                          selectedBatch?.status === "Pending Approval") && (
                          <button
                            className="btn-error"
                            onClick={openCancelBatch}
                          >
                            Cancel Batch
                          </button>
                        )}
                      </div>
                    </div>
                    {isLoading === false ? null : (
                      <LoadingContainer>
                        <LoadingSpinner className="spinner-border spinner-grow-sm" />
                      </LoadingContainer>
                    )}
                    {isLoading ? null : (
                      <div className="shipment-section-wrap">
                        <div className="row mt-4">
                          <div className="col-xl-6 col-md-6 pe-3 sub-section">
                            <div className="row section-wrap-secondary h-100">
                              <div className="col-xl-4">
                                <span className="title">Batch Number</span>
                                <p>{selectedBatch?.batch_number} </p>
                              </div>

                              <div className="col-xl-4">
                                <span className="title">Origin</span>
                                <p>{selectedBatch?.origin_station?.name} </p>
                              </div>

                              <div className="col-xl-4">
                                <span className="title">Destination</span>
                                <p>
                                  {selectedBatch?.destination_station?.name}{" "}
                                </p>
                              </div>

                              <div className="col-xl-4">
                                <span className="title">No. of shipments</span>
                                <p>{shipments?.length} </p>
                              </div>

                              {selectedBatch.status !== "Pending Approval" && (
                                <div className="col-xl-4">
                                  <span className="title">Tracking Number</span>
                                  <p>{selectedBatch?.tracking_number}</p>
                                </div>
                              )}

                              {selectedBatch.status !== "Pending Approval" && (
                                <div className="col-xl-4">
                                  <span className="title">Batch Processor</span>
                                  <p>{selectedBatch?.batch_processor} </p>
                                </div>
                              )}

                              <div className="col-xl-4">
                                <span className="title">Date</span>
                                <p>
                                  {moment(selectedBatch?.creation_date).format(
                                    "ddd MMM Do, h:mm A"
                                  )}
                                </p>
                              </div>

                              {/* <div className="col-xl-4">
                              
                              </div> */}
                            </div>
                          </div>

                          <div className="col-xl-6 col-md-6 ps-3 sub-section">
                            <div className="row section-wrap-secondary">
                              <div className=" shipment-timeline-container">
                                {timeline.map((timeline, index) => {
                                  const dateTime = moment(timeline.timestamp);
                                  const timestamp =
                                    dateTime.format("ddd MMM Do, h:mm A");
                                  return (
                                    <div
                                      style={{ marginTop: -7 }}
                                      key={timeline.id}
                                      className="d-flex align-items-start"
                                    >
                                      <div>
                                        <Box />
                                        {timeline.length === 1 ||
                                        timeline.length - 1 === index ? null : (
                                          <VerticalLine />
                                        )}
                                      </div>
                                      <div>
                                        <Timestamp>{timestamp}</Timestamp>
                                        <ActivityDesc>
                                          {timeline.description}{" "}
                                        </ActivityDesc>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="shipment-section-wrap mt-3">
                      <div className="my-3 d-flex gap-3 justify-content-end">
                        {selectedShipments.length < 1 && (
                          <SearchBox
                            onChange={handleShipmentQuery}
                            name="query"
                            value={shipmentsQuery}
                            className="form-control"
                            placeholder="Search using shipment number"
                          />
                        )}
                        {selectedShipments?.length > 0 &&
                          selectedBatch?.status === "Pending Approval" && (
                            <button
                              className="btn-primary"
                              onClick={() =>
                                openRemoveShipment(selectedShipments)
                              }
                            >
                              Remove Shipment(s)
                            </button>
                          )}
                        {selectedShipments?.length > 0 &&
                          selectedBatch?.status === "Accepted" && (
                            <button
                              className="btn-primary"
                              onClick={() => setAddBatch(true)}
                            >
                              Add to Batch
                            </button>
                          )}

                        {selectedShipments?.length > 0 &&
                          selectedBatch?.status === "Accepted" && (
                            <button
                              className="btn-primary"
                              onClick={() => setDispatchShipments(true)}
                            >
                              <FaBus />
                              Dispatch
                            </button>
                          )}
                        <div>
                          <button
                            className="feature-btn"
                            id="basic-button"
                            aria-controls={open ? "basic-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            onClick={handleClickExport}
                          >
                            <img src={ExportIcon} alt="export" />
                            Export
                            {open ? <BsChevronUp /> : <BsChevronDown />}
                          </button>
                          <Menu
                            id="basic-menu"
                            anchorEl={openExport}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                              "aria-labelledby": "basic-button",
                            }}
                          >
                            <MenuItem
                              onClick={() => {
                                exportToCSV();
                                handleClose();
                              }}
                              style={{ fontSize: 12 }}
                            >
                              Export as .csv
                            </MenuItem>
                            <MenuItem
                              style={{ fontSize: 12 }}
                              onClick={() => {
                                handleClose();
                                exportToXlsx();
                              }}
                            >
                              Export as .xlsx
                            </MenuItem>
                          </Menu>
                        </div>
                      </div>
                      <div className="table-contain w-100" >
                        <table className="shipments-table w-100">
                          <thead>
                            <tr>
                              <th scope="col">
                                <input
                                  type="checkbox"
                                  checked={
                                    selectedShipments.length ===
                                    shipments?.length
                                  }
                                  onClick={handleSelectAll}
                                />
                              </th>
                              <th scope="col">Shipment ID</th>
                              <th scope="col">Sender</th>
                              <th scope="col">Sender City</th>
                              <th scope="col">Sender State</th>
                              <th scope="col">Receiver</th>
                              <th scope="col">Receiver City</th>
                              <th scope="col">Receiver State</th>
                              <th scope="col">Transporter</th>
                              <th scope="col">Amount</th>
                              <th scope="col">Status</th>
                              <th scope="col">Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {shipments?.map((shipment) => {
                              const dateTime = moment(shipment.date_time);
                              const timestamp =
                                dateTime.format("ddd MMM Do, h:mm A");

                              const status =
                                shipment.status !== ""
                                  ? shipment.status
                                  : "Pending Delivery";
                              const statusClass = shipment.status
                                .toString()
                                .replaceAll(" ", "");

                              return (
                                <tr key={shipment.id}>
                                  <td>
                                    <input
                                      type="checkbox"
                                      checked={selectedShipments.includes(
                                        shipment.shipment_no
                                      )}
                                      onClick={() =>
                                        handleShipmentToggle(
                                          shipment.shipment_no
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="p-0">
                                    <Link
                                      to={`/shipment/${shipment.id}`}
                                      style={{
                                        padding: "20px 10px 20px 10px",
                                        color: "#475367",
                                      }}
                                    >
                                      <span className="d-flex px-4 align-items-center gap-2">
                                        {shipment.dispatched === "Yes" && (
                                          <img
                                            src={DispatchIcon}
                                            alt="dispatch"
                                            style={{ width: 20, height: 20 }}
                                          />
                                        )}
                                        {shipment.shipment_no}
                                      </span>
                                    </Link>
                                  </td>
                                  <td className="p-0">
                                    <Link
                                      to={`/shipment/${shipment.id}`}
                                      style={{
                                        padding: "20px 10px 20px 10px",
                                        color: "#475367",
                                      }}
                                    >
                                      {shipment.sender_name}
                                    </Link>
                                  </td>
                                  <td className="p-0">
                                    <Link
                                      to={`/shipment/${shipment.id}`}
                                      style={{
                                        padding: "20px 10px 20px 10px",
                                        color: "#475367",
                                      }}
                                    >
                                      {shipment.sender_city}
                                    </Link>
                                  </td>
                                  <td className="p-0">
                                    <Link
                                      to={`/shipment/${shipment.id}`}
                                      style={{
                                        padding: "20px 10px 20px 10px",
                                        color: "#475367",
                                      }}
                                    >
                                      {shipment.sender_state}
                                    </Link>
                                  </td>
                                  <td className="p-0">
                                    <Link
                                      to={`/shipment/${shipment.id}`}
                                      style={{
                                        padding: "20px 10px 20px 10px",
                                        color: "#475367",
                                      }}
                                    >
                                      {shipment.receiver_name}
                                    </Link>
                                  </td>
                                  <td className="p-0">
                                    <Link
                                      to={`/shipment/${shipment.id}`}
                                      style={{
                                        padding: "20px 10px 20px 10px",
                                        color: "#475367",
                                      }}
                                    >
                                      {shipment.receiver_city}
                                    </Link>
                                  </td>
                                  <td className="p-0">
                                    <Link
                                      to={`/shipment/${shipment.id}`}
                                      style={{
                                        padding: "20px 10px 20px 10px",
                                        color: "#475367",
                                      }}
                                    >
                                      {shipment.receiver_state}
                                    </Link>
                                  </td>

                                  <td className="p-0">
                                    <Link
                                      to={`/shipment/${shipment.id}`}
                                      style={{
                                        padding: "20px 10px 20px 10px",
                                        color: "#475367",
                                      }}
                                    >
                                      <Tooltip
                                        title={`${
                                          shipment.processor === "Self" ||
                                          shipment.processor === ""
                                            ? shipment.rider_name
                                              ? shipment.rider_name
                                              : "No Rider"
                                            : shipment.processor
                                        }`}
                                        placement="top"
                                      >
                                        <span>
                                          {shipment.processor === "Self" ||
                                          shipment.processor === ""
                                            ? shortenTexts(shipment.rider_name)
                                              ? shortenTexts(
                                                  shipment.rider_name
                                                )
                                              : "No Rider"
                                            : shortenTexts(shipment.processor)}
                                        </span>
                                      </Tooltip>
                                    </Link>
                                  </td>

                                  <td className="p-0">
                                    <Link
                                      to={`/shipment/${shipment.id}`}
                                      style={{
                                        padding: "20px 10px 20px 10px",
                                        color: "#475367",
                                      }}
                                    >
                                      {shipment?.currency === "USD"
                                        ? "$"
                                        : shipment?.currency === "NGN"
                                        ? "₦"
                                        : shipment?.currency === "GBP"
                                        ? "£"
                                        : shipment?.currency === "EUR"
                                        ? "€"
                                        : "₦"}
                                      {parseFloat(
                                        shipment.total_amount
                                      ).toLocaleString("en")}
                                    </Link>
                                  </td>

                                  <td className="p-0">
                                    <Link
                                      to={`/shipment/${shipment.id}`}
                                      style={{
                                        padding: "20px 10px 20px 10px",
                                        color: "#475367",
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      <div className={`status ${statusClass}`}>
                                        {status}
                                      </div>
                                    </Link>
                                  </td>
                                  <td className="p-0">
                                    <Link
                                      to={`/shipment/${shipment.id}`}
                                      style={{
                                        padding: "20px 10px 20px 10px",
                                        color: "#475367",
                                      }}
                                    >
                                      {timestamp}
                                    </Link>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-xl-12 col-md-12">
                {shipmentsLoading === false ? null : (
                  <LoadingContainer>
                    <LoadingSpinner className="spinner-border spinner-grow-sm" />
                  </LoadingContainer>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleBatch;
