import { Col, Modal, Row } from "react-bootstrap";
import axios from "axios";
import { getAllZonesUrl, getZonePricingUrl, setZonePricingUrl } from "../library/URLs";
import { useEffect, useState } from "react";
import { UserProfileData } from "../library/constants";
import styled from "styled-components";
import { BiX } from "react-icons/bi";
import { SelectBox, TextBox } from "../components/InputFields";
import { BsX } from "react-icons/bs";
import { toast } from "react-toastify";

const SubmitButton = styled.button``;

const ErrorMessageContainer = styled.div`
  color: #924040;
  background-color: #fde1e1;
  border-color: #fcd2d2;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid;
  margin-bottom: 20px;
  text-align: center;
`;

const SuccessMessageContainer = styled.div`
  color: #1f7556;
  background-color: #d6f3e9;
  border-color: #c2eddd;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid;
  margin-bottom: 20px;
  text-align: center;
`;

const UpdateZonePricingDialog = ({ isModalVisible, handleCloseDialog, onSubmit, zoneInfo }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    zone_from: "",
    zone_to: "",
    amount: ""
  });
  const [zones, setZones] = useState([]);
  const [isPending, setIsPending] = useState(false);

  const [isError, setErrorVisibility] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [isSuccess, setSuccessVisibility] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    if(isModalVisible) {
      getZones();
      getZoneDetails();
    }
    // eslint-disable-next-line
  }, [isModalVisible]);

  const getZoneDetails = async () => {

    const newData = { 
      ...UserProfileData(),
      zone_from: zoneInfo?.zone_from,
      zone_to: zoneInfo?.zone_to
    };
    setIsPending(true);
    await axios
      .post(getZonePricingUrl, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setIsPending(false);

        if (response["data"]["success"] === false) return;

        setFormData({
          ...formData,
          zone_from: response["data"]["data"]["zone_from"],
          zone_to: response["data"]["data"]["zone_to"],
          amount: response["data"]["data"]["amount"]
        })
      });
  };

  const getZones = async () => {

    const newData = { ...UserProfileData() };
    await axios
      .post(getAllZonesUrl, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {

        if (response["data"]["success"] === false) return;

        let zones = response?.data?.data?.map((zone) => {
          return {
            label: zone?.name,
            value: zone?.id,
          };
        });
        setZones(zones);
      });
  };

  const updateZone = (e) => {
    e.preventDefault();
    const formValues = {
      ...formData
    };
    // formValues['customer_id'] = adminID;

    const newData = { ...formValues, ...UserProfileData() };

    setErrorVisibility(false);
    setIsSubmitting(true);

    axios
      .post(setZonePricingUrl, newData)
      .then(function (response) {
        setIsSubmitting(false);
        if (response["data"]["success"] === true) {
          setSuccessVisibility(true);
          setSuccessMessage("Zone created successfully");

          setTimeout(() => {
            setFormData({
              name: "",
              country: "Nigeria",
              shipment_type: "Intracity",
              state: "",
              city: "",
            });
            onSubmit();
            handleCloseDialog();
            setSuccessVisibility(false);
          }, 3000);
        } else {
          setErrorMessage(response["data"]["message"]);
          setErrorVisibility(true);

          setTimeout(() => {
            setErrorVisibility(false);
          }, 5000);
        }
      })
      .catch(function (error) {
        setIsSubmitting(false);
        console.log(error);
        return error;
      });
  };

  const handleInput = (e) => {
    const { value, name } = e.target;

    if(name === "zone_from") {
        if(formData.zone_from === formData.zone_to) {
            toast.error("Please select a different zone");
            return;
        }
    }

    if(name === "zone_to") {
        if(formData.zone_to === formData.zone_from) {
            toast.error("Please select a different zone");
            return;
        }
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <Modal show={isModalVisible} onHide={handleCloseDialog}>
      <Modal.Header>
        <h5 className="header">UPDATE ZONE PRICE</h5>
        <BiX
          style={{ fontSize: 25, cursor: "pointer" }}
          onClick={handleCloseDialog}
        />
      </Modal.Header>
      <Modal.Body>
        {isError === false ? null : (
          <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
        )}

        {isSuccess === false ? null : (
          <SuccessMessageContainer>{successMessage}</SuccessMessageContainer>
        )}

        <p>Fill the details below to update zone price.</p>
        {
          isPending ? (
            <div className="d-flex justify-content-center py-5" >
              <span className="spinner-border spinner-grow-sm" />
            </div>
          ) : (
            <div className="query-result-container">
            <Row className="gap-3 gy-3 gap-md-0 mb-3">
            
              <Col md={6}>
                <div className="">
                  <label htmlFor="Username" className="mb-2">
                    Zone From
                  </label>
                  <SelectBox
                    name="zone_from"
                    value={formData.zone_from}
                    onChange={handleInput}
                    placeholder="Select sender zone"
                    options={zones}
                  />
                </div>
              </Col>
                <Col md={6}>
                  <label htmlFor="Username" className="mb-2">
                    Zone To
                  </label>
                  <SelectBox
                    options={zones}
                    value={formData.zone_to}
                    name="zone_to"
                    onChange={handleInput}
                    placeholder="Select receiver zone"
                  />
                </Col>

                <Col md={12}>
                  <label htmlFor="Username" className="mb-2">
                    Amount
                  </label>
                  <TextBox
                    value={formData.amount}
                    name="amount"
                    onChange={handleInput}
                    placeholder="Enter zone price"
                  />
                </Col>
            </Row>
          </div>
          )
        }
        <p className="mt-3">Would you like to continue?</p>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn-secondary mx-3" onClick={handleCloseDialog}>
          <BsX />
          Cancel
        </button>
        <SubmitButton onClick={updateZone} type="submit" className="btn-primary">
          {isSubmitting === false ? (
            <span className="button-text d-flex align-items-center gap-2">
              Proceed
            </span>
          ) : (
            <span className="spinner-border spinner-grow-sm" />
          )}
        </SubmitButton>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdateZonePricingDialog;
