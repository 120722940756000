import React, { useEffect, useState } from "react";
import GlobeIcon from "../../../../assets/settings/shipments/globe.svg";
import axios from "axios";
import { getAllZonePricingUrl } from "../../../../library/URLs";
import { UserProfileData } from "../../../../library/constants";
import styled from "styled-components";
import { TbPencil } from "react-icons/tb";
import { Tooltip } from "@mui/material";
import { BsArrowRight } from "react-icons/bs";
import AddZonePricingDialog from "../../../../modals/AddZonePricingDIalog";
import UpdateZonePricingDialog from "../../../../modals/UpdateZonePricingDialog";

const SubmitButton = styled.button`
  width: 250px;
`;

const ZonePricing = () => {
  const [allZonesPricing, setAllZonePricing] = useState({});
  const [showAddZonePricing, setShowAddZonePricing] = useState(false);
  // const [showUpdateZonePricing, setShowUpdateZonePricing] = useState(null);
  // const [statusPending, setStatusPending] = useState(null);

  const fetchAllZonePricings = async () => {
    await axios
      .post(getAllZonePricingUrl, UserProfileData())
      .then((res) => {
        if (res?.data?.success) {
          let all_zones = res?.data?.data;
          setAllZonePricing(all_zones);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    fetchAllZonePricings();
    // eslint-disable-next-line
  }, []);

  const openShowAddZonePricing = () => {
    setShowAddZonePricing(true);
  };

  const closeShowAddZonePricing = () => {
    setShowAddZonePricing(false);
  };

  // const openShowUpdateZonePricing = (zoneInfo) => {
  //   setShowUpdateZonePricing(zoneInfo);
  // };

  // const closeShowUpdateZonePricing = () => {
  //   setShowUpdateZonePricing(null);
  // };

  return (
    <div>
      <AddZonePricingDialog 
        isModalVisible={showAddZonePricing}
        handleCloseDialog={closeShowAddZonePricing}
        onSubmit={fetchAllZonePricings}
      />
{/* 
      <UpdateZonePricingDialog 
        isModalVisible={showUpdateZonePricing !== null}
        handleCloseDialog={closeShowUpdateZonePricing}
        onSubmit={fetchAllZonePricings}
        zoneInfo={showUpdateZonePricing}
      /> */}
      <div className="settings_section d-flex flex-xl-row flex-column align-items-start">
        <div className="d-flex align-items-center justify-content-between price-settings-left">
          <div className="d-flex gap-3 align-items-start">
            <img src={GlobeIcon} alt="price" className="mt-2" />
            <div>
              <p style={{ fontSize: 22, marginBottom: 8, fontWeight: "bold" }}>
                Zone Pricing
              </p>
              <span>Set pricing accross existing zones.</span>
            </div>
          </div>
        </div>
        <div className=" price-settings-right">
          <div className=" col-12">
            <div className="d-flex flex-row justify-content-between align-items-center py-3 px-4">
              <div className="d-flex flex-column">
                <span style={{ fontSize: 18, color: "#2C3E50" }}>
                  Available pricings.
                </span>
              </div>
            </div>
            <div className="py-3">
              {allZonesPricing?.length > 0 ? (
                <ul className="list-zones-none ">
                  {allZonesPricing?.map((zone) => (
                    <li
                      key={zone?.id}
                      className="d-flex py-3 justify-content-between align-items-center"
                    >
                      <label className="form-check-label d-flex align-items-center gap-3">
                        {zone?.zone_from_name} <BsArrowRight />{" "}
                        {zone?.zone_to_name} (&#8358;{" "}
                        {parseFloat(`${zone?.amount}`)?.toLocaleString("en-US")}
                        )
                      </label>
                      <div className="d-flex gap-4 align-items-center">
                        {/* <Tooltip title="Update Zone" placement="top">
                          <span className="d-flex align-items-start">
                            <TbPencil
                              style={{ fontSize: 18, cursor: "pointer" }}
                              onClick={() =>
                                openShowUpdateZonePricing({
                                  zone_from: zone.zone_from,
                                  zone_to: zone.zone_to
                                })
                              }
                            />
                          </span>
                        </Tooltip> */}
                        {/* <Tooltip title="Delete Zone" placement="top">
                          <span className="d-flex align-items-start">
                            <TbTrash
                              style={{ fontSize: 18, cursor: "pointer" }}
                              onClick={() =>
                                closeShowDeleteZone(zone.id)
                              }
                              className="text-danger"
                            />
                          </span>
                        </Tooltip> */}
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No zone pricing set.</p>
              )}
            </div>
            <div className="py-4">
              <div className=" pt-4 d-flex justify-content-end">
                <SubmitButton
                  type="submit"
                  onClick={openShowAddZonePricing}
                  className="confirm-button btn-primary"
                >
                  SET ZONE PRICING
                </SubmitButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ZonePricing;
