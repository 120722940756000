import React, { useEffect, useState } from "react";
import PriceIcon from "../../../assets/settings/price_settings/credit_card_gear.svg";
import axios from "axios";
import { UserProfileData } from "../../../library/constants";
import { toast } from "react-toastify";
import styled from "styled-components";
import { Col, Row } from "react-bootstrap";
import { getCostSettingsUrl, setCostSettingsUrl } from "../../../library/URLs";
import { SelectBox, TextBox } from "../../../components/InputFields";

const SubmitButton = styled.button`
  width: 250px;
`;

const ShipmentCostSettings = () => {
  const [costSettings, setCostSettings] = useState({
    vat: "7.5",
    vat_enabled: "No",
    transaction_fees_recipient: ""
  });
  const [edit, setEdit] = useState(false);

  const [statusPending, setStatusPending] = useState(false);

  const fetchShipmentCost = async () => {
    await axios
      .post(getCostSettingsUrl, UserProfileData())
      .then((res) => {
        if (res?.data?.success) {
          if (res?.data?.data) {
            let cost_settings = res?.data?.data;
            setCostSettings({
              ...costSettings,
              ...cost_settings
            });
          }
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    fetchShipmentCost();
    // eslint-disable-next-line
  }, []);

  const updateShipmentCost = async () => {
    const formData = {
      ...UserProfileData(),
      ...costSettings,
    };

    setStatusPending(true);

    await axios
      .post(setCostSettingsUrl, formData)
      .then((res) => {
        setStatusPending(false);
        if (res?.data?.success) {
          toast.success("Shipment cost updated successfully.");
          toggleEdit();

          setTimeout(() => {
            fetchShipmentCost();
          }, 2000)
        } else {
          toast.error(res?.data?.message);
        }
      })
      .catch((err) => {});
  };

  const toggleEdit = () => {
    setEdit(!edit)
  };

  const handleInput = (e) => {
    const { name, value } = e.target;

    setCostSettings({
      ...costSettings,
      [name]: value,
    });
  };

  return (
    <div>
      <div className="settings_section d-flex flex-xl-row flex-column align-items-start">
        <div className="d-flex align-items-center justify-content-between price-settings-left">
          <div className="d-flex gap-3 align-items-start">
            <img src={PriceIcon} alt="price" className="mt-2" />
            <div>
              <p style={{ fontSize: 22, marginBottom: 8, fontWeight: "bold" }}>
                Shipment Cost
              </p>
              <span>Customize cost settings that applies to shipment charges.</span>
            </div>
          </div>
        </div>
        <div className=" price-settings-right">
          <div className=" col-12">
            <Row className="gy-5 mb-2" >
              <Col md={6}>
                <div>
                  <label htmlFor="Description" className="mb-1">
                    VAT Fee
                  </label>
                  <TextBox
                    name="vat"
                    value={costSettings?.vat}
                    onChange={handleInput}
                    disabled
                    placeholder="Enter connection type"
                  />
                </div>
              </Col>

              <Col md={6}>
                <div>
                  <label htmlFor="Description" className="mb-1">
                    Enable VAT?
                  </label>
                  <SelectBox
                    name="vat_enabled"
                    value={costSettings?.vat_enabled}
                    options={[
                      {label: "Yes", value: "Yes"},
                      {label: "No", value: "No"}
                    ]}
                    disabled={!edit}
                    onChange={handleInput}
                    placeholder="Select vat option"
                  />
                </div>
              </Col>

              <Col md={12}>
                <div>
                  <label htmlFor="Description" className="mb-1">
                    Who should be charged payment service transaction fees?
                  </label>
                  <SelectBox
                    name="transaction_fees_recipient"
                    value={costSettings.transaction_fees_recipient}
                    options={[
                      {label: "Operator", value: "Operator"},
                      {label: "Customer", value: "Customer"}
                    ]}
                    disabled={!edit}
                    onChange={handleInput}
                    placeholder="Select transaction fee option"
                  />
                </div>
              </Col>
            </Row>

            <div className=" pt-5 d-flex justify-content-end">
              {
                edit ? (
                  <span className="d-flex gap-3" >
                    <SubmitButton
                      type="submit"
                      onClick={toggleEdit}
                      className="btn-secondary"
                    >
                      CANCEL
                    </SubmitButton>
                    
                    <SubmitButton
                      type="submit"
                      onClick={updateShipmentCost}
                      className=" btn-primary"
                    >
                      {statusPending === false ? (
                        <span className="button-text">UPDATE</span>
                      ) : (
                        <span className="spinner-border spinner-grow-sm" />
                      )}
                    </SubmitButton>
                  </span>
                ) : (
                  <SubmitButton
                    type="submit"
                    onClick={toggleEdit}
                    className=" btn-primary"
                  >
                    EDIT
                  </SubmitButton>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShipmentCostSettings;
