import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { UserProfileData } from "../../../library/constants";
import axios from "axios";
import {
  editAdminPrivilegesURL,
  getAdminActivitiesURL,
  getAdminPrivilegesURL,
} from "../../../library/URLs";
import styled from "styled-components";
import * as BoxIcons from "react-icons/bi";
import { Col, Row } from "react-bootstrap";
import { Switch } from "@mui/material";
import moment from "moment";
import ActivitiesIcon from "../../../assets/admins/activities.svg";
import ActivitiesActiveIcon from "../../../assets/admins/activities-active.svg";
import PrivilegesIcon from "../../../assets/admins/star.svg";
import PrivilegesActiveIcon from "../../../assets/admins/star-active.svg";
import { styled as mui_styled } from "@mui/material/styles";
import ShipmentsIcon from "../../../assets/admins/shipment.svg";
import PaymentsIcon from "../../../assets/admins/payment.svg";
import CustomersIcon from "../../../assets/admins/customer.svg";
import TransportIcon from "../../../assets/admins/transporter.svg";
import FundsIcon from "../../../assets/admins/fund.svg";
import AdminIcon from "../../../assets/admins/admin.svg";

const LoadingContainer = styled.div`
  height: 450px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin: auto;
`;

const EmptyContainer = styled.div`
  height: 450px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SubmitButton = styled.button`
  width: 250px;
`;

const ErrorMessageContainer = styled.div`
  color: #924040;
  background-color: #fde1e1;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid #fcd2d2;
  margin-bottom: 20px;
  margin-top: 30px;
  text-align: center;
`;

const SuccessMessageContainer = styled.div`
  color: #1f7556;
  background-color: #d6f3e9;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid #c2eddd;
  margin-bottom: 20px;
  margin-top: 30px;
  text-align: center;
`;

const ParcelflowSwitch = mui_styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 34,
  height: 16,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#15AB68",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 14,
    height: 12,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#98A2B3",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const AdminActivities = ({ adminID, adminPrivileges }) => {
  const navigator = useNavigate();

  const form = useRef(null);

  const [isUpdatingAdmin, setIsUpdatingAdmin] = useState(false);

  const [activeTab, setActiveTab] = useState("AdminActivities");

  const [isError, setErrorVisibility] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [isSuccess, setSuccessVisibility] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const [isLoading, setIsLoading] = useState(true);
  const [isEmpty, setIsEmpty] = useState(false);
  const [adminActivities, setAdminActivities] = useState([]);
  const [privileges, setPrivileges] = useState({});

  const data = {
    employee_id: adminID,
    limit: 20,
    offset: 0,
    status: "All",
  };

  const newData = { ...data, ...UserProfileData() };

  useEffect(() => {
    axios
      .post(getAdminActivitiesURL, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log(response);
        setIsLoading(false);

        if (response["data"]["success"] === false) return;

        const adminActivities = response["data"]["data"];

        console.log(adminActivities.length);

        if (adminActivities.length === 0) setIsEmpty(true);

        setAdminActivities(adminActivities);
      });
      // eslint-disable-next-line
  }, []);

  useEffect(() => {
    axios
      .post(getAdminPrivilegesURL, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log(response);

        if (response["data"]["success"] === false) return;

        let result_list = {...response["data"]["data"]};
        const { station, user_id, last_name, first_name, id, company_id, name, employee_type, ...permissions } = result_list;
        setPrivileges(permissions);
      });
      // eslint-disable-next-line
  }, []);

  const updateAdmin = () => {
    const formValues = {};
    formValues["privileges"] = privileges;
    formValues["employee_id"] = adminID;

    const newData = { ...formValues, ...UserProfileData() };

    setIsUpdatingAdmin(true);
    axios
      .post(editAdminPrivilegesURL, newData)
      .then((response) => {
        console.log(response);
        setIsUpdatingAdmin(false);
        window.scrollTo(0, 0);

        if (response["data"]["success"] === true) {
          setSuccessVisibility(true);
          setSuccessMessage("Successfully updated admin.");

          setTimeout(() => {
            setSuccessVisibility(false);
          }, 3000);
        } else {
          const message = response["data"]["message"];

          setErrorVisibility(true);
          setErrorMessage(message);
          setTimeout(() => {
            setErrorVisibility(false);
          }, 3000);
        }
      })
      .catch((error) => console.log(error));
  };

  const goToPage = (type, typeID) => {
    if (type === "Shipment") {
      navigator("/shipment/" + typeID);
    } else if (type === "Customer") {
      navigator("/customer/" + typeID);
    } else if (type === "Transporter") {
      navigator("/transporter/" + typeID);
    }
  };

  const handleToggle = (e) => {
    const { name } = e.target;

    setPrivileges({
      ...privileges,
      [name]: privileges[name] === 1 ? 0 : 1,
    });
  };

  return (
    <div className="card border-0" style={{ background: "#FFF" }}>
      <div className="card-body">
        <div className="single-admin-tab">
          <span
            onClick={(e) => setActiveTab("AdminActivities")}
            className={`admin-tab admin-tab-left ${
              activeTab === "AdminActivities" ? "admin-tab-active" : null
            } ${
              adminPrivileges["edit_employee"] !== 1 && "admin-tab-left-admin"
            }`}
          >
            {activeTab === "AdminActivities" ? (
              <img src={ActivitiesActiveIcon} alt="icon" />
            ) : (
              <img src={ActivitiesIcon} alt="icon" />
            )}
            Activities
          </span>
          {adminPrivileges["edit_employee"] === 1 ? (
            <span
              onClick={(e) => setActiveTab("AdminPrivileges")}
              className={`admin-tab admin-tab-right ${
                activeTab === "AdminPrivileges" ? "admin-tab-active" : null
              }`}
            >
              {activeTab === "AdminPrivileges" ? (
                <img src={PrivilegesActiveIcon} alt="icon" />
              ) : (
                <img src={PrivilegesIcon} alt="icon" />
              )}
              Privileges
            </span>
          ) : null}
        </div>

        <div
          className={`${
            activeTab === "AdminActivities"
              ? "show-customer-tab"
              : "hide-customer-tab"
          }`}
        >
          {adminActivities.length === 0 ? (
            <EmptyContainer>
              Admin has no recorded activities yet...
            </EmptyContainer>
          ) : (
            <div className="admin-activities-container">
              {adminActivities.map((adminActivity) => {
                const dateTime = moment(
                  adminActivity["date_created"],
                  "YYYY-MM-DD h:mm:ss"
                );
                // const timestamp = dateTime.format('ddd MMM Do, h:mm A');

                return (
                  <div key={adminActivity["id"]} className="mt-3">
                    <div className="d-flex flex-row justify-content-between">
                      <p style={{ margin: 0 }}>
                        {adminActivity["description"]}
                      </p>
                      <span style={{ fontSize: 12 }}>{dateTime.fromNow()}</span>
                    </div>
                    {adminActivity["sub_description"] === "" ? null : (
                      <div
                        className="d-flex flex-row justify-content-between align-items-center"
                        style={{
                          marginLeft: 20,
                          border: "1px dashed #AAB7B8",
                          padding: "10px",
                          borderRadius: 4,
                          marginTop: 5,
                        }}
                      >
                        <div className="d-flex flex-row align-items-center">
                          {adminActivity["extras"] === "" ? (
                            <span className="shipment-class">
                              <BoxIcons.BiPackage className="icon" />
                            </span>
                          ) : (
                            <img
                              style={{ width: 32, height: 32 }}
                              src={adminActivity["extras"]}
                              alt=""
                            />
                          )}
                          <span>{adminActivity["sub_description"]}</span>
                        </div>
                        {adminActivity["button_text"] === "" ? null : (
                          <span
                            onClick={(e) =>
                              goToPage(
                                adminActivity["type"],
                                adminActivity["type_id"]
                              )
                            }
                            style={{
                              color: "#37458b",
                              backgroundColor: "#dee3fa",
                              border: "1px solid #ced5f8",
                              padding: "5px 15px 5px 15px",
                              borderRadius: "3px",
                              fontSize: "14px",
                              cursor: "pointer",
                            }}
                          >
                            {adminActivity["button_text"]}
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <div
          className={`${
            activeTab === "AdminPrivileges"
              ? "show-customer-tab"
              : "hide-customer-tab"
          }`}
        >
          {isError === false ? null : (
            <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
          )}

          {isSuccess === false ? null : (
            <SuccessMessageContainer>{successMessage}</SuccessMessageContainer>
          )}
          {Object.keys(privileges).length > 0 && (
            <form ref={form}>
              <Row className="form-row mt-4 px-3">
                <Col className="form-group col-xl-6 pe-2 col-md-6">
                  <div className="privileges-card">
                    <div className="top-banner">
                      <img src={ShipmentsIcon} alt="icon" />
                      <p className="m-0">Shipment Privileges</p>
                    </div>
                    <div className="options-card">
                      <div className="d-flex p-3 border-bottom flex-row justify-content-between align-items-center">
                        <label htmlFor="Full Name">Create Shipment</label>
                        <ParcelflowSwitch
                          defaultValue="0"
                          name="create_shipment"
                          onChange={handleToggle}
                          checked={privileges.create_shipment === 1}
                          className="react-switch"
                        />
                      </div>

                      <div className="d-flex p-3 border-bottom flex-row justify-content-between align-items-center">
                        <label htmlFor="Full Name">Approve Shipment</label>
                        <ParcelflowSwitch
                          name="approve_shipment"
                          onChange={handleToggle}
                          checked={privileges.approve_shipment === 1}
                          className="react-switch"
                        />
                      </div>

                      <div className="d-flex p-3 border-bottom flex-row justify-content-between align-items-center">
                        <label htmlFor="Full Name">Route Shipment</label>
                        <ParcelflowSwitch
                          name="route_shipment"
                          onChange={handleToggle}
                          checked={privileges.route_shipment === 1}
                          className="react-switch"
                        />
                      </div>

                      <div className="d-flex p-3 border-bottom flex-row justify-content-between align-items-center">
                        <label htmlFor="Full Name">Reroute Shipment</label>
                        <ParcelflowSwitch
                          name="reroute_shipment"
                          onChange={handleToggle}
                          checked={privileges.reroute_shipment === 1}
                          className="react-switch"
                        />
                      </div>

                      <div className="d-flex p-3 border-bottom flex-row justify-content-between align-items-center">
                        <label htmlFor="Full Name">Cancel Shipment</label>
                        <ParcelflowSwitch
                          name="cancel_shipment"
                          onChange={handleToggle}
                          checked={privileges.cancel_shipment === 1}
                          className="react-switch"
                        />
                      </div>

                      <div className="d-flex p-3 border-bottom flex-row justify-content-between align-items-center">
                        <label htmlFor="Full Name">Reject Shipment</label>
                        <ParcelflowSwitch
                          name="reject_shipment"
                          onChange={handleToggle}
                          checked={privileges.reject_shipment === 1}
                          className="react-switch"
                        />
                      </div>

                      <div className="d-flex p-3 flex-row justify-content-between align-items-center">
                        <label htmlFor="Full Name">Transfer Shipment</label>
                        <ParcelflowSwitch
                          name="transfer_shipment"
                          onChange={handleToggle}
                          checked={privileges.transfer_shipment === 1}
                          className="react-switch"
                        />
                      </div>
                    </div>
                  </div>
                </Col>

                <Col className="form-group col-xl-6 ps-2 col-md-6 ">
                  <div className="privileges-card">
                    <div className="top-banner">
                      <img src={PaymentsIcon} alt="icon" />
                      <p className="m-0">Payment Privileges</p>
                    </div>
                    <div className="options-card">
                      <div className="d-flex border-bottom p-3 flex-row justify-content-between align-items-center">
                        <label htmlFor="Full Name">Approve Wallet Topup</label>
                        <ParcelflowSwitch
                          name="approve_wallet_topup"
                          onChange={handleToggle}
                          checked={privileges.approve_wallet_topup === 1}
                          className="react-switch"
                        />
                      </div>

                      <div className="d-flex border-bottom p-3 flex-row justify-content-between align-items-center">
                        <label htmlFor="Full Name">Mark Shipment As Paid</label>
                        <ParcelflowSwitch
                          name="mark_payment_as_paid"
                          onChange={handleToggle}
                          checked={privileges.mark_payment_as_paid === 1}
                          className="react-switch"
                        />
                      </div>

                      <div className="d-flex border-bottom p-3 flex-row justify-content-between align-items-center">
                        <label htmlFor="Full Name">
                          Mark Shipment As Unpaid
                        </label>
                        <ParcelflowSwitch
                          name="mark_payment_as_unpaid"
                          onChange={handleToggle}
                          checked={privileges.mark_payment_as_unpaid === 1}
                          className="react-switch"
                        />
                      </div>

                      <div className="d-flex border-bottom p-3 flex-row justify-content-between align-items-center">
                        <label htmlFor="Full Name">Fund Customer Wallet</label>
                        <ParcelflowSwitch
                          name="fund_user_wallet"
                          onChange={handleToggle}
                          checked={privileges.fund_user_wallet === 1}
                          className="react-switch"
                        />
                      </div>

                      <div className="d-flex p-3 flex-row justify-content-between align-items-center">
                        <label htmlFor="Full Name">
                          Deduct Customer Wallet
                        </label>
                        <ParcelflowSwitch
                          name="deduct_user_wallet"
                          onChange={handleToggle}
                          checked={privileges.deduct_user_wallet === 1}
                          className="react-switch"
                        />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col className="form-group col-xl-6 col-md-6 pe-2">
                  <div className="privileges-card">
                    <div className="top-banner">
                      <img src={CustomersIcon} alt="icon" />
                      <p className="m-0">Customer Privileges</p>
                    </div>
                    <div className="options-card">
                      <div className="d-flex p-3 border-bottom flex-row justify-content-between align-items-center">
                        <label htmlFor="Full Name">
                          Send Push Notifications
                        </label>
                        <ParcelflowSwitch
                          name="send_push_notifications"
                          onChange={handleToggle}
                          checked={privileges.send_push_notifications === 1}
                          className="react-switch"
                        />
                      </div>

                      <div className="d-flex p-3 border-bottom flex-row justify-content-between align-items-center">
                        <label htmlFor="Full Name">Send Email</label>
                        <ParcelflowSwitch
                          name="send_email"
                          onChange={handleToggle}
                          checked={privileges.send_email === 1}
                          className="react-switch"
                        />
                      </div>

                      <div className="d-flex p-3 border-bottom flex-row justify-content-between align-items-center">
                        <label htmlFor="Full Name">Send SMS</label>
                        <ParcelflowSwitch
                          name="send_sms"
                          onChange={handleToggle}
                          checked={privileges.send_sms === 1}
                          className="react-switch"
                        />
                      </div>

                      <div className="d-flex p-3 border-bottom flex-row justify-content-between align-items-center">
                        <label htmlFor="Full Name">
                          Make Contract Customer
                        </label>
                        <ParcelflowSwitch
                          name="make_contract_user"
                          onChange={handleToggle}
                          checked={privileges.make_contract_user === 1}
                          className="react-switch"
                        />
                      </div>

                      <div className="d-flex p-3 border-bottom flex-row justify-content-between align-items-center">
                        <label htmlFor="Full Name">Make Regular Customer</label>
                        <ParcelflowSwitch
                          name="make_regular_user"
                          onChange={handleToggle}
                          checked={privileges.make_regular_user === 1}
                          className="react-switch"
                        />
                      </div>

                      <div className="d-flex p-3 flex-row justify-content-between align-items-center">
                        <label htmlFor="Full Name">Reply Messages</label>
                        <ParcelflowSwitch
                          name="reply_messages"
                          onChange={handleToggle}
                          checked={privileges.reply_messages === 1}
                          className="react-switch"
                        />
                      </div>
                    </div>
                  </div>
                </Col>

                <Col className="form-group col-xl-6 col-md-6 ps-2">
                  <div className="privileges-card">
                    <div className="top-banner">
                      <img src={TransportIcon} alt="icon" />
                      <p className="m-0">Transporter Privileges</p>
                    </div>
                    <div className="options-card">
                      <div className="d-flex p-3 border-bottom flex-row justify-content-between align-items-center">
                        <label htmlFor="Full Name">Create Transporter</label>
                        <ParcelflowSwitch
                          name="create_rider"
                          onChange={handleToggle}
                          checked={privileges.create_rider === 1}
                          className="react-switch"
                        />
                      </div>

                      <div className="d-flex p-3 border-bottom flex-row justify-content-between align-items-center">
                        <label htmlFor="Full Name">
                          Change Transporter Details
                        </label>
                        <ParcelflowSwitch
                          name="change_rider_details"
                          onChange={handleToggle}
                          checked={privileges.change_rider_details === 1}
                          className="react-switch"
                        />
                      </div>

                      <div className="d-flex p-3 border-bottom flex-row justify-content-between align-items-center">
                        <label htmlFor="Full Name">
                          Deactivate Transporter
                        </label>
                        <ParcelflowSwitch
                          name="deactivate_rider"
                          onChange={handleToggle}
                          checked={privileges.deactivate_rider === 1}
                          className="react-switch"
                        />
                      </div>

                      <div className="d-flex p-3 border-bottom flex-row justify-content-between align-items-center">
                        <label htmlFor="Full Name">
                          Reactivate Transporter
                        </label>
                        <ParcelflowSwitch
                          name="reactivate_rider"
                          onChange={handleToggle}
                          checked={privileges.reactivate_rider}
                          className="react-switch"
                        />
                      </div>

                      <div className="d-flex p-3 flex-row justify-content-between align-items-center">
                        <label htmlFor="Full Name">Delete Transporter</label>
                        <ParcelflowSwitch
                          name="delete_rider"
                          onChange={handleToggle}
                          checked={privileges.delete_rider === 1}
                          className="react-switch"
                        />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col className="form-group col-xl-6 col-md-6 pe-2">
                  <div className="privileges-card">
                    <div className="top-banner">
                      <img src={FundsIcon} alt="icon" />
                      <p className="m-0">Fund Management</p>
                    </div>
                    <div className="options-card">
                      <div className="d-flex p-3 border-bottom flex-row justify-content-between align-items-center">
                        <label htmlFor="Full Name">Transfer Funds</label>
                        <ParcelflowSwitch
                          name="transfer_funds"
                          onChange={handleToggle}
                          checked={privileges.transfer_funds === 1}
                          className="react-switch"
                        />
                      </div>

                      <div className="d-flex p-3 border-bottom flex-row justify-content-between align-items-center">
                        <label htmlFor="Full Name">Buy Airtime</label>
                        <ParcelflowSwitch
                          name="buy_airtime"
                          onChange={handleToggle}
                          checked={privileges.buy_airtime === 1}
                          className="react-switch"
                        />
                      </div>

                      <div className="d-flex p-3 border-bottom flex-row justify-content-between align-items-center">
                        <label htmlFor="Full Name">Buy Electricity</label>
                        <ParcelflowSwitch
                          name="buy_electricity"
                          onChange={handleToggle}
                          checked={privileges.buy_electricity === 1}
                          className="react-switch"
                        />
                      </div>

                      <div className="d-flex p-3 border-bottom flex-row justify-content-between align-items-center">
                        <label htmlFor="Full Name">Buy Internet</label>
                        <ParcelflowSwitch
                          name="buy_internet"
                          onChange={handleToggle}
                          checked={privileges.buy_internet === 1}
                          className="react-switch"
                        />
                      </div>

                      <div className="d-flex p-3 flex-row justify-content-between align-items-center">
                        <label htmlFor="Full Name">Buy Toll</label>
                        <ParcelflowSwitch
                          name="buy_toll"
                          onChange={handleToggle}
                          checked={privileges.buy_toll === 1}
                          className="react-switch"
                        />
                      </div>
                    </div>
                  </div>
                </Col>

                <Col className="form-group col-xl-6 ps-2 col-md-6">
                  <div className="privileges-card">
                    <div className="top-banner">
                      <img src={AdminIcon} alt="icon" />
                      <p className="m-0">Admin Privileges</p>
                    </div>
                    <div className="options-card">
                      <div className="d-flex p-3 border-bottom flex-row justify-content-between align-items-center">
                        <label htmlFor="Full Name">Create New Admin</label>
                        <ParcelflowSwitch
                          name="create_employee"
                          onChange={handleToggle}
                          checked={privileges.create_employee === 1}
                          className="react-switch"
                        />
                      </div>

                      <div className="d-flex p-3 border-bottom flex-row justify-content-between align-items-center">
                        <label htmlFor="Full Name">Edit Admin Details</label>
                        <ParcelflowSwitch
                          name="edit_employee"
                          onChange={handleToggle}
                          checked={privileges.edit_employee === 1}
                          className="react-switch"
                        />
                      </div>

                      <div className="d-flex p-3 border-bottom flex-row justify-content-between align-items-center">
                        <label htmlFor="Full Name">Suspend Admin</label>
                        <ParcelflowSwitch
                          name="suspend_employee"
                          onChange={handleToggle}
                          checked={privileges.suspend_employee === 1}
                          className="react-switch"
                        />
                      </div>

                      <div className="d-flex p-3 border-bottom flex-row justify-content-between align-items-center">
                        <label htmlFor="Full Name">Unsuspend Admin</label>
                        <ParcelflowSwitch
                          name="unsuspend_employee"
                          onChange={handleToggle}
                          checked={privileges.unsuspend_employee === 1}
                          className="react-switch"
                        />
                      </div>

                      <div className="d-flex p-3 flex-row justify-content-between align-items-center">
                        <label htmlFor="Full Name">Delete Admin</label>
                        <ParcelflowSwitch
                          name="delete_employee"
                          onChange={handleToggle}
                          checked={privileges.delete_employee === 1}
                          className="react-switch"
                        />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row className="mt-4">
                <Col className="form-group col-xl-6 col-md-6 pe-2">
                  <div className="privileges-card">
                    <div className="top-banner">
                      <img src={FundsIcon} alt="icon" />
                      <p className="m-0">Pages Privileges</p>
                    </div>
                    <div className="options-card">
                      <div className="d-flex p-3 border-bottom flex-row justify-content-between align-items-center">
                        <label htmlFor="Full Name">Show Dashboard</label>
                        <ParcelflowSwitch
                          name="show_dashboard"
                          onChange={handleToggle}
                          checked={privileges.show_dashboard === 1}
                          className="react-switch"
                        />
                      </div>

                      <div className="d-flex p-3 border-bottom flex-row justify-content-between align-items-center">
                        <label htmlFor="Full Name">Show Shipments</label>
                        <ParcelflowSwitch
                          name="show_shipments"
                          onChange={handleToggle}
                          checked={privileges.show_shipments === 1}
                          className="react-switch"
                        />
                      </div>

                      <div className="d-flex p-3 border-bottom flex-row justify-content-between align-items-center">
                        <label htmlFor="Full Name">Show Customers</label>
                        <ParcelflowSwitch
                          name="show_customers"
                          onChange={handleToggle}
                          checked={privileges.show_customers === 1}
                          className="react-switch"
                        />
                      </div>

                      <div className="d-flex p-3 border-bottom flex-row justify-content-between align-items-center">
                        <label htmlFor="Full Name">Show Transactions</label>
                        <ParcelflowSwitch
                          name="show_transactions"
                          onChange={handleToggle}
                          checked={privileges.show_transactions === 1}
                          className="react-switch"
                        />
                      </div>

                      <div className="d-flex p-3 flex-row justify-content-between align-items-center">
                        <label htmlFor="Full Name">Show Admins</label>
                        <ParcelflowSwitch
                          name="show_admins"
                          onChange={handleToggle}
                          checked={privileges.show_admins === 1}
                          className="react-switch"
                        />
                      </div>

                      <div className="d-flex p-3 flex-row justify-content-between align-items-center">
                        <label htmlFor="Full Name">Show Transporters</label>
                        <ParcelflowSwitch
                          name="show_transporters"
                          onChange={handleToggle}
                          checked={privileges.show_transporters === 1}
                          className="react-switch"
                        />
                      </div>

                      <div className="d-flex p-3 flex-row justify-content-between align-items-center">
                        <label htmlFor="Full Name">Show Reports</label>
                        <ParcelflowSwitch
                          name="show_reports"
                          onChange={handleToggle}
                          checked={privileges.show_reports === 1}
                          className="react-switch"
                        />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>

              <div className="d-flex flex-row my-5 justify-content-end">
                <SubmitButton
                  onClick={(e) => updateAdmin()}
                  type="button"
                  className="btn-primary"
                >
                  {isUpdatingAdmin === false ? (
                    <span className="button-text">Update Privileges</span>
                  ) : (
                    <span className="spinner-border spinner-grow-sm" />
                  )}
                </SubmitButton>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminActivities;
