import { Col, Modal, Row } from "react-bootstrap";
import axios from "axios";
import {
  updateZoneUrl,
  getCitiesByStateNameUrl,
  getZoneDetailsUrl,
} from "../library/URLs";
import { useEffect, useState } from "react";
import { UserProfileData } from "../library/constants";
import styled from "styled-components";
import { BiX } from "react-icons/bi";
import { MultiSelectBox, SelectBox, TextBox } from "../components/InputFields";
import { BsX } from "react-icons/bs";
import nigeriaStates from "../files/nigeriaStates.json";

const SubmitButton = styled.button``;

const ErrorMessageContainer = styled.div`
  color: #924040;
  background-color: #fde1e1;
  border-color: #fcd2d2;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid;
  margin-bottom: 20px;
  text-align: center;
`;

const SuccessMessageContainer = styled.div`
  color: #1f7556;
  background-color: #d6f3e9;
  border-color: #c2eddd;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid;
  margin-bottom: 20px;
  text-align: center;
`;

const UpdateZoneDialog = ({
  isModalVisible,
  handleCloseDialog,
  zoneID,
  onSubmit,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    country: "Nigeria",
    state: "",
    selected_states: [],
    city: "",
    selected_cities: [],
    shipment_type: "Intracity",
  });
  const [citiesPending, setCitiesPending] = useState(false);
  const [citiesOptions, setCitiesOptions] = useState([]);
  const [zonePending, setZonePending] = useState(false);

  let state_options = nigeriaStates?.data?.map((state) => {
    return {
      label: state?.name,
      value: state?.name,
    };
  });

  useEffect(() => {
    if (zoneID) {
      getZoneDetails();
    }
    // eslint-disable-next-line
  }, [zoneID]);

  const [isError, setErrorVisibility] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [isSuccess, setSuccessVisibility] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const getZoneDetails = async () => {
    const data = {
      zone_id: zoneID,
    };

    const newData = { ...data, ...UserProfileData() };
    setZonePending(true);
    await axios
      .post(getZoneDetailsUrl, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setZonePending(false);

        if (response["data"]) {
          // let
        }
      });
  };

  useEffect(() => {
    getCities();
    // eslint-disable-next-line
  }, [formData.state]);

  const getCities = async () => {
    const data = {
      country: formData.country,
      state: formData.state,
    };

    const newData = { ...data, ...UserProfileData() };
    setCitiesPending(true);
    await axios
      .post(getCitiesByStateNameUrl, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setCitiesPending(false);

        if (response["data"]["success"] === false) return;

        let cities = response?.data?.data?.map((city) => {
          return {
            label: city,
            value: city,
          };
        });
        setCitiesOptions(cities);
      });
  };

  const updateZone = (e) => {
    e.preventDefault();
    const formValues = {
      ...formData,
      city: formData.selected_cities.map((city) => city.value).join("|"),
      zone_id: zoneID
    };
    // formValues['customer_id'] = adminID;

    const newData = { ...formValues, ...UserProfileData() };

    setErrorVisibility(false);
    setIsSubmitting(true);

    axios
      .post(updateZoneUrl, newData)
      .then(function (response) {
        setIsSubmitting(false);
        if (response["data"]["success"] === true) {
          setSuccessVisibility(true);
          setSuccessMessage("Zone created successfully");

          setTimeout(() => {
            setFormData({
              name: "",
              country: "Nigeria",
              shipment_type: "Intracity",
              state: "",
              city: "",
            });
            onSubmit();
            handleCloseDialog();
            setSuccessVisibility(false);
          }, 3000);
        } else {
          setErrorMessage(response["data"]["message"]);
          setErrorVisibility(true);

          setTimeout(() => {
            setErrorVisibility(false);
          }, 5000);
        }
      })
      .catch(function (error) {
        setIsSubmitting(false);
        console.log(error);
        return error;
      });
  };

  const handleInput = (e) => {
    const { value, name } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleMultiSelection = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <Modal show={isModalVisible} onHide={handleCloseDialog}>
      <Modal.Header>
        <h5 className="header">UPDATE ZONE</h5>
        <BiX
          style={{ fontSize: 25, cursor: "pointer" }}
          onClick={handleCloseDialog}
        />
      </Modal.Header>
      <Modal.Body>
        {isError === false ? null : (
          <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
        )}

        {isSuccess === false ? null : (
          <SuccessMessageContainer>{successMessage}</SuccessMessageContainer>
        )}

        <p>Fill the details below to update zone.</p>
        {zonePending ? (
          <div className="py-5 d-flex justify-content-center">
            <span className="spinner-border spinner-grow-sm" />
          </div>
        ) : (
          <div className="query-result-container">
            <div className="mb-3">
              <label htmlFor="Description" className="mb-2">
                Name
              </label>
              <TextBox
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInput}
                placeholder="Enter name"
              />
            </div>

            <Row className="gap-3 gy-3 gap-md-0 mb-3">
              {/* <Col md={6}>
              <div className="">
                <label htmlFor="Username" className="mb-2">
                  Shipment Type
                </label>
                <SelectBox
                  type="text"
                  name="shipment_type"
                  value={formData.shipment_type}
                  onChange={handleInput}
                  placeholder="Select shipment type"
                  options={[
                    {label: "Within a city", value: "Intracity"},
                    {label: "Across nigeria states", value: "Interstate"}
                  ]}
                />
              </div>
            </Col> */}
              <Col md={6}>
                <div className="">
                  <label htmlFor="Username" className="mb-2">
                    Country
                  </label>
                  <TextBox
                    type="text"
                    name="country"
                    value={formData.country}
                    disabled
                    onChange={handleInput}
                    placeholder="Enter zone country"
                  />
                </div>
              </Col>
              {formData.shipment_type === "Interstate" ? (
                <Col md={12}>
                  <label htmlFor="Username" className="mb-2">
                    State
                  </label>
                  <MultiSelectBox
                    options={state_options}
                    value={formData.selected_states}
                    name="selected_states"
                    onChange={(val) =>
                      handleMultiSelection("selected_states", val)
                    }
                    placeholder="Select states"
                  />
                </Col>
              ) : (
                <Col md={6}>
                  <label htmlFor="Username" className="mb-2">
                    State
                  </label>
                  <SelectBox
                    options={state_options}
                    value={formData.state}
                    name="state"
                    onChange={handleInput}
                    placeholder="Select state"
                  />
                </Col>
              )}

              {formData.shipment_type === "Intracity" && (
                <Col md={12}>
                  <label htmlFor="Username" className="mb-2">
                    City
                  </label>
                  <span className="d-flex gap-2">
                    {citiesPending && (
                      <span className="spinner-border spinner-grow-sm" />
                    )}

                    <MultiSelectBox
                      name="selected_cities"
                      value={formData.selected_cities}
                      onChange={(val) =>
                        handleMultiSelection("selected_cities", val)
                      }
                      options={citiesOptions}
                      placeholder="Select cities"
                    />
                  </span>
                </Col>
              )}
            </Row>
          </div>
        )}
        <p className="mt-3">Would you like to continue?</p>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn-secondary mx-3" onClick={handleCloseDialog}>
          <BsX />
          Cancel
        </button>
        <SubmitButton onClick={updateZone} type="submit" className="btn-primary">
          {isSubmitting === false ? (
            <span className="button-text d-flex align-items-center gap-2">
              Proceed
            </span>
          ) : (
            <span className="spinner-border spinner-grow-sm" />
          )}
        </SubmitButton>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdateZoneDialog;
