import PayServicesSettings from "./PayServicesSettings";
import ShipmentCostSettings from "./ShipmentCostSettings";

const PaymentSettings = () => {
  
  return (
    <div>
      <PayServicesSettings />
      <ShipmentCostSettings />
    </div>
  );
}

export default PaymentSettings