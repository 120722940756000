import React, { useState } from "react";
import ConfirmIcon from "../../../assets/create-shipment/payment-page.svg";
import { HiOutlineSwitchVertical } from "react-icons/hi";
import { toast } from "react-toastify";
import axios from "axios";
import { createLocalShipmentUrl } from "../../../library/URLs";
import moment from "moment";
import { UserProfileData } from "../../../library/constants";
import { useNavigate } from "react-router-dom";
import { SessionManagement } from "../../../library/SessionManagement";
import Tooltip from "@mui/material/Tooltip";

const Confirm = ({ previousPage, formData, pricingMethod }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!term) {
      toast.error("Click the checkbox to confirm your shipment!");
      return;
    }

    let data = {
      sender_details: {
        ...formData?.sender_details,
        email: formData.sender_details.email
          ? formData.sender_details.email
          : SessionManagement.getEmail(),
        state: formData.shipment_state,
      },
      receiver_details: {
        ...formData?.receiver_details,
        email: formData?.receiver_details?.email
          ? formData?.receiver_details?.email
          : SessionManagement.getEmail(),
        state: formData.shipment_state,
      },
      payment_method: formData?.payment_method,
      processing_station: formData?.pickup_station,
      amount: formData?.amount,
      transporter: formData?.transporter,
      description: formData?.description,
      insurance: "No",
      carrier_id: formData?.carrier ? formData?.carrier?.id : "",
      shipment_value: formData?.item_value,
      weight: formData?.item_weight,
      insurance_fee: "0",
      vehicle_type: formData?.vehicle_type,
      insurance_type: "",
      shipment_plan: formData?.shipment_plan,
      customer_id:
        formData.use_customer_account === "No"
          ? ""
          : formData?.customer_wallet_id,
      shipping_date: moment(formData?.shipping_date).format("MM/DD/YYYY"),
    };

    setIsSubmitting(true);

    const newData = { ...data, ...UserProfileData() };
    await axios
      .post(createLocalShipmentUrl, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setIsSubmitting(false);

        if (response["data"]["success"]) {
          let shipmentID = response["data"]["data"]["shipment-id"];
          window.scrollTo(0, 0);

          toast.success(response["data"]["data"]["message"]);
          setTerm(false);
          setTimeout(() => {
            navigate("/shipment/" + shipmentID);
          }, 2000);
        } else {
          toast.error(response["data"]["message"]);
        }
      });
    console.log("book ", formData);
  };
  const [term, setTerm] = useState(false);

  let customer_wallet_account = formData.suggestedCustomers?.find(
    (cust) => parseInt(cust?.id) === parseInt(formData?.customer_wallet_id)
  );

  let customer_wallet = customer_wallet_account
    ? `${customer_wallet_account?.fullname} - ${customer_wallet_account?.email}`
    : "";

  const shortenTexts = (text) => {
    if (text) {
      if (text?.length > 22) {
        return `${text?.slice(0, 22)}...`;
      } else {
        return text;
      }
    }
  };

  return (
    <div className="create-shipment-grid">
      <div className="create-shipment-info">
        <img src={ConfirmIcon} alt="icon" />
        <div>
          <div className="create-shipment-info-top">
            <p className="create-shipment-info-title">Confirm Details</p>
            <p className="create-shipment-info-sub">
              Confirm details accuracy before proceeding
            </p>
          </div>
        </div>
      </div>
      <div className="create-shipment-info-form">
        <div className="confirm-details-wrapper">
          <div className="confirm-details">
            <p className="confirm-details-title">Shipment Summary</p>
            <div className="confirm-details-address">
              {/* Sender Info */}
              <div className="confirm-details-address-option">
                <p className="confirm-details-address-option-text-1">
                  From (sender details)
                </p>
                <div>
                  <p className="confirm-details-address-option-text-2">
                    {formData.sender_details.fullname}{" "}
                  </p>
                  <p className="confirm-details-address-option-text-1">
                    {formData.sender_details.address}{" "}
                  </p>
                </div>
              </div>
              {/* Receiver Info */}
              <div className="confirm-details-address-option">
                <p className="confirm-details-address-option-text-1">
                  To (receiver details)
                </p>
                <div>
                  <p className="confirm-details-address-option-text-2">
                    {formData.receiver_details.fullname}{" "}
                  </p>
                  <p className="confirm-details-address-option-text-1">
                    {formData.receiver_details.address}{" "}
                  </p>
                </div>
              </div>
              <div className="confirm-details-address-icon">
                <HiOutlineSwitchVertical />
              </div>
            </div>
            <div>
              <div className="confirm-details-payment">
                <div className="confirm-details-payment-option">
                  <p className="confirm-details-payment-option-text-1">
                    Payment Method
                  </p>
                  <p className="confirm-details-payment-option-text-2">
                    {formData.payment_method}
                  </p>
                  {formData?.customer_wallet_id && (
                    <div className="mt-1">
                      <p className="confirm-details-payment-option-text-1">
                        Customer Account
                      </p>
                      <p className="confirm-details-payment-option-text-2">
                        {
                          <Tooltip title={`${customer_wallet}`} placement="top">
                            <span>{shortenTexts(customer_wallet)}</span>
                          </Tooltip>
                        }
                      </p>
                    </div>
                  )}
                </div>
                <div className="confirm-details-payment-option">
                  {
                    formData?.transporter && (
                      <div>
                        <p className="confirm-details-payment-option-text-1">
                          Transporter
                        </p>
                        <p className="confirm-details-payment-option-text-2">
                          {
                            formData?.transporters?.find(
                              (transporter) =>
                                parseInt(transporter.value) ===
                                parseInt(formData.transporter)
                            )?.label
                          }
                        </p>
                      </div>
                    )
                  }

                  {
                    formData?.carrier && (
                      <div>
                        <p className="confirm-details-payment-option-text-1">
                          Processor
                        </p>
                        <p className="confirm-details-payment-option-text-2">
                          {formData?.shipment_plan}
                        </p>
                      </div>
                    )
                  }
                </div>
                <div className="confirm-details-payment-option">
                  <p className="confirm-details-payment-option-text-1">
                    Station
                  </p>
                  <p className="confirm-details-payment-option-text-2">
                    {
                      formData?.stations?.find(
                        (station) =>
                          station.value === parseInt(formData?.pickup_station)
                      )?.label
                    }
                  </p>
                </div>
              </div>
              <div className="confirm-summary">
                <p className="confirm-summary-title">Package Summary</p>
                <div className="confirm-summary-cost">
                  <p className="confirm-summary-cost-text-1">Description</p>
                  <div>
                    <p className="confirm-summary-cost-text-2">
                      {formData?.description}
                    </p>
                  </div>
                </div>
                <div className="confirm-summary-cost">
                  <p className="confirm-summary-cost-text-1">Shipment Cost</p>
                  <div>
                    <p className="confirm-summary-cost-text-2">
                      &#8358;{" "}
                      {parseFloat(formData?.amount)?.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </p>
                    <p className="confirm-summary-cost-text-3">Inc. VAT</p>
                  </div>
                </div>
                <div className="confirm-summary-term">
                  <input
                    type="checkbox"
                    checked={term}
                    onChange={() => setTerm(!term)}
                    name=""
                    id=""
                  />
                  <p>
                    I accept that the declaration above is a proper and accurate
                    description of the contents of my package.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end gap-2">
            <button
              className="create-shipment-info-form-btn-secondary"
              onClick={previousPage}
            >
              Previous
            </button>
            <button
              className="create-shipment-info-form-btn"
              onClick={handleSubmit}
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <span className="spinner-border spinner-grow-sm" />
              ) : (
                "Process Shipment"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Confirm;
