import "../styles/home.scss";
import "../styles/single-shipment.scss";
import "../styles/components.scss";
import "../styles/create-admin.scss";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import * as React from "react";
import styled from "styled-components";
import axios from "axios";
import {
  editAdminUrl,
  getAdminDetailsURL,
  getStationsURL,
  uploadImageURL,
} from "../library/URLs";
import { UserProfileData } from "../library/constants";
import { useNavigate, useParams } from "react-router-dom";
import SubscriptionInactive from "../components/SubscriptionInactive";
import { MultiSelectBox, PhoneFieldBox, TextBox } from "../components/InputFields";
import { toast } from "react-toastify";

const ErrorMessageContainer = styled.div`
  color: #924040;
  background-color: #fde1e1;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid #fcd2d2;
  margin-bottom: 20px;
  text-align: center;
`;

const SuccessMessageContainer = styled.div`
  color: #1f7556;
  background-color: #d6f3e9;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid #c2eddd;
  margin-bottom: 20px;
  text-align: center;
`;

const SteppedNumber = styled.div`
  border-radius: 50%;
  height: 24px;
  width: 24px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #008561;
`;

const SteppedTitle = styled.span`
  font-size: 20px;
`;

const SubmitButton = styled.button`
  width: 250px;
`;

const EditAdmin = () => {
  const navigator = useNavigate();
  const { adminID } = useParams();

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
    station: [],
    profile_image_url: "",
  });

  const handleInput = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handlePhone = (code) => {
    setFormData({
      ...formData,
      phone: code,
    });
  };

  const [isCreatingAdmin, setIsCreatingAdmin] = useState(false);

  const [isError, setErrorVisibility] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [isSuccess, setSuccessVisibility] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const [stations, setStations] = useState([]);

  const form = useRef(null);

  const data = {
    limit: 20,
    status: "All",
  };

  const newData = { ...data, ...UserProfileData() };

  useEffect(() => {
    axios
      .post(getStationsURL, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response["data"]["success"] === false) return;

        const stations = response["data"]["data"]["data"]?.map((station) => {
          return {
            label: station.station_name,
            value: station.id,
          };
        });

        setStations(stations);
        getAdminInfo(stations)
      });
    // eslint-disable-next-line
  }, []);

  const getAdminInfo = async (company_stations) => {
    const newData = { employee_id: adminID, ...UserProfileData() };
    await axios
      .post(getAdminDetailsURL, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response["data"]["success"] === false) return;

        const adminDetails = response["data"];

        const name = adminDetails["data"]["full_name"];
        const phoneNumber = adminDetails["data"]["phone"];
        const email = adminDetails["data"]["email"];
        const profileImageURL = adminDetails["data"]["profile_image_url"];
        const station = adminDetails["data"]["station"];
        const station_list = station?.split("|");
        const selected_stations = company_stations
          .filter((a_station) => station_list?.includes(`${a_station.value}`))
          ?.map((a_station) => {
            return {
              label: a_station?.label,
              value: a_station?.value,
            };
          });

        setFormData({
          ...formData,
          name: name,
          phone: phoneNumber,
          email: email,
          profile_image_url: profileImageURL,
          station: selected_stations,
        });
      });
  };

  const updateAdminDetails = (profileImageURLResponse) => {
    const formValues = { ...formData };
    formValues["profile_image_url"] =
      formData.profile_image_url === ""
        ? formData.profile_image_url
        : profileImageURLResponse;

    formValues["admin_id"] = adminID;
    formValues["station"] = formData.station.map(station => station.value).join("|")

    const newData = { ...formValues, ...UserProfileData() };

    axios
      .post(editAdminUrl, newData)
      .then((response) => {
        console.log(response);
        setIsCreatingAdmin(false);
        window.scrollTo(0, 0);

        if (response["data"]["success"] === true) {
          setSuccessVisibility(true);
          setSuccessMessage("Successfully updated admin.");

          setTimeout(() => {
            navigator(`/admin/${adminID}`);
          }, 3000);
        } else {
          const message = response["data"]["message"];

          setErrorVisibility(true);
          setErrorMessage(message);
          setTimeout(() => {
            // setErrorVisibility(false);
          }, 3000);
        }
      })
      .catch((error) => console.log(error));
  };

  function showError(message) {
    window.scrollTo(0, 0);
    setErrorVisibility(true);
    setErrorMessage(message);

    setTimeout(() => {
      setErrorVisibility(false);
    }, 3000);
  }

  const uploadProfileImage = () => {
    const fileInput = document.querySelector("#profile-image");

    console.log("faf ", formData);
    if (formData.name === "") {
      showError("Please enter valid name");
      return false;
    }

    if (formData.email === "") {
      showError("Please enter valid email address");
      return false;
    }

    if (formData.phone === "") {
      showError("Please enter valid phone number");
      return false;
    }

    setIsCreatingAdmin(true);

    if (fileInput.files[0] === undefined && formData.profile_image_url === "") {
      toast.error("Upload a valid image!");
      return;
    }

    const formValues = {};
    formValues["document"] = fileInput.files[0];

    axios
      .post(uploadImageURL, formValues, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        const profileImageURLResponse = response["data"];

        setFormData({
          ...formData,
          profileImageURLResponse,
        });
        updateAdminDetails(profileImageURLResponse);
      })
      .catch((error) => console.log(error));
  };

  const handleSelectStations = (value) => {
    // Update selected_shipping_options field which adjusts shipping_plans field.
    
    setFormData({
      ...formData,
      station: value,
    });
  };

  return (
    <div className="home">
      <Sidebar />
      <div className="main">
        <Navbar />
        <div
          className="page pb-5"
          style={{ maxWidth: "100vw", overflow: "hidden" }}
        >
          <SubscriptionInactive />

          <div className="d-flex flex-row justify-content-between mb-2 mx-3">
            <h5 className="header">EDIT ADMIN</h5>
            <span>Home</span>
          </div>
          <div className="container-fluid">
            <div className="card bg-light border-0 shadow p-4">
              <div className="">
                {isError === false ? null : (
                  <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
                )}

                {isSuccess === false ? null : (
                  <SuccessMessageContainer>
                    {successMessage}
                  </SuccessMessageContainer>
                )}

                <form ref={form}>
                  <section>
                    <div className="d-flex align-items-center mb-2">
                      <SteppedNumber>1</SteppedNumber>
                      <SteppedTitle className="mx-2">
                        Basic Details
                      </SteppedTitle>
                    </div>
                    <Row className="form-row">
                      <Col className="form-group col-xl-4 col-md-6 col-12 mb-3 mb-xl-0">
                        <label htmlFor="Full Name">Full Name</label>
                        <TextBox
                          id="name"
                          name="name"
                          value={formData.name}
                          onChange={handleInput}
                          placeholder="Enter full name"
                          type="text"
                        />
                      </Col>

                      <Col className="form-group col-xl-4 col-md-6 col-12 mb-3 mb-xl-0">
                        <label htmlFor="Email">Email</label>
                        <TextBox
                          id="email"
                          name="email"
                          type="email"
                          value={formData.email}
                          onChange={handleInput}
                          placeholder="Enter email address"
                        />
                      </Col>

                      <Col className="form-group col-xl-4 col-md-6 col-12 mb-3 mb-xl-0">
                        <label htmlFor="Phone Number">Phone Number</label>
                        <PhoneFieldBox
                          id="phone"
                          name="phone"
                          value={formData.phone}
                          onChange={handlePhone}
                          placeholder="Enter phone number"
                        />
                      </Col>
                    </Row>

                    <Row className="form-row mt-3">
                      <Col className="form-group col-xl-12 col-md-12">
                        <label htmlFor="Width">Station</label>
                        <MultiSelectBox 
                          name="station"
                          options={stations}
                          value={formData.station}
                          onChange={handleSelectStations}
                          placeholder="Select station"
                        />
                      </Col>
                    </Row>

                    {/* <Row className="form-row mt-3">
                            <Col className="form-group col-xl-12 col-md-12">
                                <label htmlFor="Width">Station</label>
                                <select name="station" className="form-select">
                                    <option value="">Choose Station</option>
                                    {stations.map((station) => {
                                        return (<option key={station.id}
                                                        value={station.id}>{station.address + " " + station.address_2 + " " + station.city_name + " " + station.state}
                                        </option>);
                                    })}
                                </select>
                            </Col>
                        </Row> */}
                  </section>

                  {/*<section className="mt-5">*/}
                  {/*    <div className="d-flex align-items-center mb-2">*/}
                  {/*        <SteppedNumber>3</SteppedNumber>*/}
                  {/*        <SteppedTitle className="mx-2">Profile Image</SteppedTitle>*/}
                  {/*    </div>*/}
                  {/*    <Dropzone onDrop={onDrop} accept={"image/*"} />*/}
                  {/*</section>*/}

                  <section className="mt-5">
                    <div className="d-flex align-items-center mb-2">
                      <SteppedNumber>3</SteppedNumber>
                      <SteppedTitle className="mx-2">
                        Profile Image
                      </SteppedTitle>
                    </div>
                    <input
                      type="file"
                      id="profile-image"
                      className="form-control-file"
                    />
                  </section>

                  <div className="d-flex flex-row my-5 justify-content-end">
                    <SubmitButton
                      onClick={(e) => uploadProfileImage()}
                      type="button"
                      className="confirm-button"
                    >
                      {isCreatingAdmin === false ? (
                        <span className="button-text">UPDATE ADMIN</span>
                      ) : (
                        <span className="spinner-border spinner-grow-sm" />
                      )}
                    </SubmitButton>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditAdmin;
