import { Col, Row } from "react-bootstrap";
import * as React from "react";
import styled from "styled-components";
import { useEffect, useRef, useState } from "react";
import { SessionManagement } from "../../../library/SessionManagement";
import { UserProfileData } from "../../../library/constants";
import axios from "axios";
import { getWhatsappNumbersURL } from "../../../library/URLs";
import AddPhoneDialog from "../../../modals/AddPhone";
import "../../../styles/shipments.scss";
import ActivatePhone from "../../../modals/AddPhone/ActivatePhone";

const SubmitButton = styled.button`
  width: 250px;
`;

const ActivateButton = styled.button`
  width: 180px;
`;

const LoadingContainer = styled.div`
  height: 200px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin: auto;
`;

const ErrorMessageContainer = styled.div`
  color: #924040;
  background-color: #fde1e1;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid #fcd2d2;
  margin-bottom: 20px;
  text-align: center;
  margin-top: 20px;
`;

const SuccessMessageContainer = styled.div`
  color: #1f7556;
  background-color: #d6f3e9;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid #c2eddd;
  margin-bottom: 20px;
  text-align: center;
  margin-top: 20px;
`;

const numberInfo = [
    {
        phoneNumber: "+234 803830 3787",
        businessName: "Demo Business",
        date: "19/12/2023",
    },
    {
        phoneNumber: "+234 803830 3787",
        businessName: "Demo Business",
        date: "19/12/2023",
    },
    {
        phoneNumber: "+234 803830 3787",
        businessName: "Demo Business",
        date: "19/12/2023",
    },
    {
        phoneNumber: "+234 803830 3787",
        businessName: "Demo Business",
        date: "19/12/2023",
    },
    {
        phoneNumber: "+234 803830 3787",
        businessName: "Demo Business",
        date: "19/12/2023",
    },
    {
        phoneNumber: "+234 803830 3787",
        businessName: "Demo Business",
        date: "19/12/2023",
    },
    {
        phoneNumber: "+234 803830 3787",
        businessName: "Demo Business",
        date: "19/12/2023",
    },
];

const PhoneNumbers = () => {
    const [isError, setErrorVisibility] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [isSuccess, setSuccessVisibility] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");

    const [phoneNumbers, setPhoneNumbers] = useState([]);

    const [selectedPhoneNumber, setSelectedPhoneNumber] = useState(null);

    const [addPhone, setAddPhone] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    // update selected phone number with the desired phone number
    // if selected phone number is empty
    const handlePhoneNumberSelect = (phone) => {
        setSelectedPhoneNumber(phone);
    };

    const phoneNumber = SessionManagement.getPhone();

    // useEffect(() => {
    //     const objectUrl = URL.createObjectURL(selectedFile)
    //     setPreview(objectUrl)
    //
    //     // free memory when ever this component is unmounted
    //     return () => URL.revokeObjectURL(objectUrl)
    // }, [selectedFile])
    useEffect(() => {
        getWhatsappPhoneNumbers();
    }, []);

    const getWhatsappPhoneNumbers = () => {
        const newData = { ...UserProfileData() };
        setIsLoading(true);

        axios
            .post(getWhatsappNumbersURL, newData)
            .then(function (response) {
                setIsLoading(false);

                setPhoneNumbers(response?.data?.data);
            })
            .catch(function (error) {
                setIsLoading(false);
                return error;
            });
    };

    return (
        <>
            {isError === false ? null : (
                <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
            )}

            {isSuccess === false ? null : (
                <SuccessMessageContainer>{successMessage}</SuccessMessageContainer>
            )}

            <AddPhoneDialog
                isModalVisible={addPhone}
                handleCloseDialog={() => setAddPhone(false)}
            />

            <ActivatePhone
                isModalVisible={selectedPhoneNumber !== null}
                handleCloseDialog={() => setSelectedPhoneNumber(null)}
                phoneData={selectedPhoneNumber}
                updateList={getWhatsappPhoneNumbers}
            />

            <p
                style={{
                    fontSize: 22,
                    marginTop: 30,
                    marginBottom: 0,
                    fontWeight: "bold",
                }}
            >
                Phone Numbers
            </p>
            <span>View registered phone numbers and add new numbers</span>
            <div>
                <div className="row">
                    <div className="d-flex flex-row my-5 justify-content-end">
                        <SubmitButton
                            type="button"
                            onClick={() => setAddPhone(true)}
                            className="confirm-button"
                        >
                            <span className="button-text">ADD PHONE NUMBER</span>
                        </SubmitButton>
                    </div>
                </div>
                <div className="card bg-light border-0 shadow p-4 min-vh-100">
                    <table className="shipments-table">
                        <thead>
                            <tr>
                                <th scope="col">Business Name</th>
                                <th scope="col">Phone Number</th>
                                <th scope="col">Verification Status</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        {isLoading === false && (
                            <tbody>
                                {phoneNumbers.length < 1 && (
                                    <tr>
                                        <td colSpan={3} align="center">
                                            No Phone Number available
                                        </td>
                                    </tr>
                                )}
                                {phoneNumbers.length > 0 &&
                                    phoneNumbers.map((number, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{number?.display_name}</td>
                                                <td>{number?.phone_number}</td>
                                                <td>{number?.verification_status}</td>
                                                <td>
                                                    {number?.platform_type === "NOT_APPLICABLE" && (
                                                        <ActivateButton
                                                            type="button"
                                                            onClick={() =>
                                                                handlePhoneNumberSelect(number?.phone_number)
                                                            }
                                                            className="confirm-button"
                                                        >
                                                            <span className="button-text">Activate</span>
                                                        </ActivateButton>
                                                    )}
                                                </td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        )}
                    </table>
                    {/* {isRetrievingMore === false ? null :
                                <div style={{textAlign:"center", marginTop:40}}>
                                    <LoadingSpinner className="spinner-border spinner-grow-sm"/> Retrieving More..
                                </div>
                            */}

                    {isLoading === false ? null : (
                        <LoadingContainer>
                            <LoadingSpinner className="spinner-border spinner-grow-sm" />
                        </LoadingContainer>
                    )}
                </div>
            </div>
        </>
    );
};

export default PhoneNumbers;
