import { Col, Modal, Row } from "react-bootstrap";
import axios from "axios";
import {
  addShipmentTrackingDetailsUrl,
  getAPICarriersUrl,
  uploadWaybillURL,
} from "../library/URLs";
import { useEffect, useState } from "react";
import { UserProfileData } from "../library/constants";
import styled from "styled-components";
import { BiX } from "react-icons/bi";
import { SelectBox, TextBox } from "../components/InputFields";
import { toast } from "react-toastify";

const SubmitButton = styled.button`
  width: 250px;
`;

const AddTrackingDetailsDialog = ({
  isModalVisible,
  handleCloseDialog,
  shipmentID,
  reload,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    api_type_id: "",
    tracking_number: "",
    waybill_url: "",
    waybill_file: null,
  });
  const [apiCarriers, setApiCarriers] = useState([]);
  const [waybillLoading, setWaybillLoading] = useState(false);

  const fetchApiCarriers = async () => {
    await axios
      .post(getAPICarriersUrl, UserProfileData())
      .then((res) => {
        if (res.data.success) {
          let api_carriers = res.data.data?.map((carrier) => {
            return {
              label: carrier.name,
              value: carrier.id,
            };
          });

          setApiCarriers(api_carriers);
        }
      })
      .catch((err) => {});
  };

  const uploadWaybillDocument = (e) => {
    // const fileInput = document.querySelector('#waybill-document');
    const { files } = e.target;

    const formValues = {};
    formValues["image"] = files[0];

    setWaybillLoading(true);

    axios
      .post(uploadWaybillURL, formValues, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        const waybillURL = response["data"];

        setFormData({
          ...formData,
          waybill_url: waybillURL,
        });
        setWaybillLoading(false);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    fetchApiCarriers();
  }, []);

  const addTrackingDetails = (e) => {
    e.preventDefault();
    const formValues = {
      ...formData,
      shipment_id: shipmentID
    };

    const newData = { ...formValues, ...UserProfileData() };

    setIsSubmitting(true);

    axios
      .post(addShipmentTrackingDetailsUrl, newData)
      .then(function (response) {
        if (response["data"]["success"] === true) {
          setIsSubmitting(false);
          toast.success(response["data"]["data"]);

          setTimeout(() => {
            setFormData({
              api_type_id: "",
              tracking_number: "",
              waybill_url: "",
              waybill_file: null,
            });
            reload();
            handleCloseDialog();
          }, 2000);
        } else {
          toast.error(response["data"]["message"]);
        }
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  };

  const handleInput = (e) => {
    const { value, name } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <Modal show={isModalVisible} onHide={handleCloseDialog}>
      <Modal.Header>
        <h5 className="header">ADD TRACKING DETAILS</h5>
        <BiX
          style={{ fontSize: 25, cursor: "pointer" }}
          onClick={handleCloseDialog}
        />
      </Modal.Header>
      <Modal.Body>
        <div className="query-result-container">
          <Row className="gap-3 gy-3 mt-3 gap-md-0">
            <Col className=" col-12 col-md-6">
              <label htmlFor="Description">Shipment Carrier</label>
              <SelectBox
                name="api_type_id"
                className="form-control"
                placeholder="Select shipment carrier"
                value={formData.api_type_id}
                onChange={handleInput}
                options={apiCarriers}
              />
            </Col>
            <Col className=" col-12 col-md-6">
              <label htmlFor="Description">Tracking Number</label>
              <TextBox
                name="tracking_number"
                className="form-control"
                placeholder="Enter tracking number"
                value={formData.tracking_number}
                onChange={handleInput}
              />
            </Col>

            <Col className=" col-12 col-md-12">
              <div className="d-flex gap-2 align-items-center">
                <label htmlFor="Description">Upload Waybill Document</label>
                {waybillLoading && (
                  <span className="spinner-border spinner-grow-sm" />
                )}
              </div>
              <input
                type="file"
                name="waybill"
                value={formData?.waybill_file}
                onChange={uploadWaybillDocument}
                id="waybill"
                className="form-control"
              />
            </Col>
          </Row>
        </div>
        <p className="mt-3">Would you like to continue?</p>
      </Modal.Body>
      <Modal.Footer>
        <button className="cancel-button mx-3" onClick={handleCloseDialog}>
          CANCEL
        </button>
        <SubmitButton
          onClick={addTrackingDetails}
          type="submit"
          className="confirm-button"
        >
          {isSubmitting === false ? (
            <span className="button-text">PROCEED</span>
          ) : (
            <span className="spinner-border spinner-grow-sm" />
          )}
        </SubmitButton>
      </Modal.Footer>
    </Modal>
  );
};

export default AddTrackingDetailsDialog;
