import React, { useEffect, useState } from "react";
import ReceiverIcon from "../../../assets/create-shipment/receiver-page.svg";
import { Col, Row } from "react-bootstrap";
import { PhoneFieldBox, SelectContactTextBox, SelectTextBox, TextBox } from "../../../components/InputFields";
import {
  getAddressSuggestionsURL,
  saveAddressUrl,
  getCitiesByStateNameUrl,
} from "../../../library/URLs";
import { UserProfileData } from "../../../library/constants";
import axios from "axios";
import { toast } from "react-toastify";

const Receiver = ({
  nextPage,
  previousPage,
  formData,
  setFormData
}) => {
  const [locations, setLocations] = useState([]);
  const [showAddressSuggestions, setShowAddressSuggestions] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [saveAddress, setSaveAddress] = useState(false);
  const [citiesPending, setCitiesPending] = useState(false);
  const [citiesOptions, setCitiesOptions] = useState(false);

  useEffect(() => {
      getCities();
      //eslint-disable-next-line
    }, [formData?.shipment_state]);

  const saveCustomerAddress = async () => {
    const data = {
      full_name: formData.receiver_details.fullname,
      address_1: formData.receiver_details.address,
      address_2: "",
      country: "Nigeria",
      state: "",
      city: "",
      postcode: "",
      email_address: formData.receiver_details.email,
      phone_number: formData.receiver_details.phone,
    };

    const newData = { ...data, ...UserProfileData() };
    setSaveLoading(true);
    await axios
      .post(saveAddressUrl, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setSaveLoading(false);

        if (response["data"]["success"] === false) {
          toast.error(response["data"]["message"]);
          return;
        };

        toast.success(response["data"]["data"]);
        setSaveAddress(false);
        nextPage();
      });
  };

  const fetchLocationSuggestions = async (keyword) => {
    const data = {
      keyword: keyword,
    };

    const newData = { ...data, ...UserProfileData() };
    await axios
      .post(getAddressSuggestionsURL, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response["data"]["success"] === false) return;

        const predictions = response["data"]["predictions"];

        setShowAddressSuggestions(true);
        setLocations(predictions);
      });
  };
  const handleInput = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      receiver_details: {
        ...formData.receiver_details,
        [name]: value,
      },
    });
  };

  const getCities = async () => {
    const data = {
      country: "Nigeria",
      state: formData?.shipment_state,
    };

    const newData = { ...data, ...UserProfileData() };
    setCitiesPending(true);
    await axios
      .post(getCitiesByStateNameUrl, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setCitiesPending(false);

        if (response["data"]["success"] === false) return;

        let cities = response?.data?.data?.map((city) => {
          return {
            label: city,
            value: city,
          };
        });
        setCitiesOptions(cities);
      });
  };

  const handleAddress = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      receiver_details: {
        ...formData.receiver_details,
        [name]: value,
      },
    });

    fetchLocationSuggestions(value);
  };

  const validatePage = () => {
    if (formData.receiver_details.fullname === "") {
      return true;
    } else if (formData.receiver_details.address === "") {
      return true;
    } else if (formData.receiver_details.phone === "") {
      return true;
    }

    return;
  };

  const handlePhoneInput = (code) => {
    setFormData({
      ...formData,
      receiver_details: {
        ...formData.receiver_details,
        phone: code,
      },
    });
  };

  const selectPlace = (location) => {
    setFormData({
      ...formData,
      receiver_details: {
        ...formData.receiver_details,
        address: location,
      },
    });

    setShowAddressSuggestions(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
   
    if(saveAddress) {
      saveCustomerAddress();
    } else {
      nextPage();
    }
  };

  const handleReceiverText = (customer) => {
    let fullname = customer.full_name;
    let email = customer.email_address;
    let phone = customer.phone_number;
    let address = customer.address_1;

    let updated_info = {
      fullname,
      email,
      phone,
      address
    };

    setFormData({
      ...formData,
      receiver_details: {
        ...formData.receiver_details,
        ...updated_info
      },
    });
  };

  const handleSelectTextbox = (name, value) => {
    setFormData({
      ...formData,
      receiver_details: {
        ...formData.receiver_details,
        [name]: value,
      },
    });
  };

  const setReceiverText = (value) => {
    setFormData({
      ...formData,
      receiver_details: {
        ...formData.receiver_details,
        fullname: value
      },
    });
  };


  return (
    <div className="create-shipment-grid">
      <div className="create-shipment-info">
        <img src={ReceiverIcon} alt="icon" />
        <div>
          <div className="create-shipment-info-top">
            <p className="create-shipment-info-title">Receiver Details</p>
            <p className="create-shipment-info-sub">
              Who is receiving this package and where are they located?
            </p>
          </div>
        </div>
      </div>
      <div className="create-shipment-info-form">
        <Row className="create-shipment-info-form-row">
          <Col sm={12} className="create-shipment-info-form-row-input">
            <label htmlFor="fullname">FullName</label>
            <SelectContactTextBox 
              name="fullname"
              value={formData.receiver_details.fullname}
              onChange={handleReceiverText}
              handleText={setReceiverText}
              placeholder="Receiver's Full Name"
            />
          </Col>
          <Col sm={12}>
            <label htmlFor="email">Email Address (optional)</label>
            <TextBox
              name="email"
              type="email"
              value={formData.receiver_details.email}
              onChange={handleInput}
              placeholder="Receiver's Email"
            />
          </Col>
          <Col sm={12} md={6}>
            <label htmlFor="email">Phone Number</label>
            <PhoneFieldBox
              value={formData.receiver_details.phone}
              onChange={handlePhoneInput}
            />
          </Col>
          <Col sm={12} lg={6} className="create-shipment-info-form-row-input">
            <label htmlFor="city">City (optional)</label>
            <div className="d-flex gap-1">
              {citiesPending && (
                <span className="spinner-border spinner-grow-sm" />
              )}
              <SelectTextBox
                name="city"
                value={formData.receiver_details.city}
                onChange={handleSelectTextbox}
                placeholder="Select available cities"
                options={citiesOptions}
              />
            </div>
          </Col>
          <Col sm={12}>
            <label htmlFor="email">Address [Dropoff]</label>
            <TextBox
              name="address"
              value={formData.receiver_details.address}
              onChange={handleAddress}
              placeholder="Input your address [dropoff] here"
            />
            <div
              style={{ width: "50%" }}
              className={`suggestions-field ${
                showAddressSuggestions ? "suggestions-field-visible" : null
              }`}
            >
              {locations?.map((suggestions) => {
                const mainText = suggestions.structured_formatting.main_text;
                const description =
                  suggestions.structured_formatting.secondary_text;
                const desc = `${suggestions.structured_formatting.main_text}, ${suggestions.structured_formatting.secondary_text}`;
                return (
                  <div
                    onClick={(e) => selectPlace(desc)}
                    key={suggestions.place_id}
                    className="single-suggestion"
                  >
                    <span>{mainText}</span>
                    <span style={{ fontSize: 13, marginTop: -5 }}>
                      {description}
                    </span>
                  </div>
                );
              })}
            </div>
          </Col>
          <Col sm={12} className="create-shipment-info-form-row-input">
            <div className="confirm-summary-term d-flex gap-2 align-items-center">
              <input
                type="checkbox"
                checked={saveAddress}
                onChange={() => setSaveAddress(!saveAddress)}
                name=""
                id=""
              />
              <p className="m-0" >Save Receiver Info</p>
            </div>
          </Col>
        </Row>
        <div className="d-flex justify-content-end gap-2">
          <button
            className="create-shipment-info-form-btn-secondary"
            onClick={previousPage}
          >
            Previous
          </button>
          <button
            className="create-shipment-info-form-btn"
            onClick={handleSubmit}
            disabled={validatePage()}
          >
            {(saveLoading) ? (
              <span className="spinner-border spinner-grow-sm" />
            ) : (
              "Proceed"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Receiver;
