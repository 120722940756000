import { useState } from "react"
import PaystackSettings from "./PaystackSettings"
import StripeSettings from "./StripeSettings"
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import CardIcon from "../../../assets/settings/payments/credit_card.svg";
import PaystackIcon from "../../../assets/payment-logos/paystack.svg";
import StripeIcon from "../../../assets/payment-logos/stripe.jpeg";

const PayServicesSettings = () => {
  const [selectedPayment, setSelectedPayment] = useState();

  const handleSelectPaymentService = (id) => {
    if (selectedPayment === id) {
      setSelectedPayment(null);
    } else {
      setSelectedPayment(id);
    }
  };
  
  return (
    <div>
      <div className="settings_section d-flex flex-xl-row flex-column align-items-start">
        <div className="d-flex align-items-center justify-content-between price-settings-left">
          <div className="d-flex gap-3 align-items-start">
            <img src={CardIcon} alt="price" className="mt-2" />
            <div>
              <p style={{ fontSize: 22, marginBottom: 8, fontWeight: "bold" }}>
                Payment Service Settings
              </p>
              <span>Modify your payment services credentials.</span>
            </div>
          </div>
        </div>
        <div className=" price-settings-right">
          <div className=" col-12 courier-list">
            <div className="w-100 courier-list-item">
              <div
                className="d-flex justify-content-between align-items-center courier-list-item-header"
                onClick={() => handleSelectPaymentService(1)}
              >
                <div className="d-flex gap-2 align-items-md-center flex-column flex-md-row">
                  <img
                    className="courier-list-item-img"
                    src={PaystackIcon}
                    alt={"paystack"}
                  />
                  <p className="courier-list-item-name">Paystack</p>
                </div>
                {selectedPayment === 1 ? (
                  <BsChevronUp />
                ) : (
                  <BsChevronDown />
                )}
              </div>
              <div
                className={`courier-list-item-bottom ${
                  selectedPayment === 1
                    ? "courier-list-item-bottom-active"
                    : "courier-list-item-bottom-inactive"
                }`}
              >
                <div className="courier-list-item-form-wrap">
                  <PaystackSettings opened={selectedPayment === 1} />
                </div>
              </div>
            </div>

            <div className="w-100 courier-list-item">
              <div
                className="d-flex justify-content-between align-items-center courier-list-item-header"
                onClick={() => handleSelectPaymentService(2)}
              >
                <div className="d-flex gap-2 align-items-md-center flex-column flex-md-row">
                  <img
                    className="courier-list-item-img"
                    src={StripeIcon}
                    alt={"stripe"}
                  />
                  <p className="courier-list-item-name">Stripe</p>
                </div>
                {selectedPayment === 2 ? (
                  <BsChevronUp />
                ) : (
                  <BsChevronDown />
                )}
              </div>
              <div
                className={`courier-list-item-bottom ${
                  selectedPayment === 2
                    ? "courier-list-item-bottom-active"
                    : "courier-list-item-bottom-inactive"
                }`}
              >
                <div className="courier-list-item-form-wrap">
                  <StripeSettings opened={selectedPayment === 2} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PayServicesSettings